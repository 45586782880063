import moment from "moment";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { firestore } from "../firebase/FirebaseConfig";
// import { confirmToJoinRound } from "./sentNotification";


const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';


export const notiTypeDef = (noti) => {

    // const handleConfirm = async (roundRequestId,noti) => {
    //     console.log(noti)
    //   const timeStampInSeconds = Math.floor(Date.now() / 1000);
    //   const notificationId = noti.notificationId
    //     const docRef = doc(firestore, 'roundRequest', roundRequestId);
    //     await updateDoc(docRef, {
    //         status: "confirm",
    //         timestamp: timeStampInSeconds
    //     });

    //     const docRef2 = doc(firestore, 'notifications', notificationId);
    //     await updateDoc(docRef2, {
    //         isConfirm: true,
    //     });

    //     confirmToJoinRound(noti.toUserId, noti.fromUserId, timeStampInSeconds, noti.roundId);
    // };

    const handleTojoin = async (roundId) => {
        window.location.href = `/join-round?id=${roundId}`
    }

    switch (noti.type) {
        case 'add':
            return (
                <>
                    <TopNavNoti noti={noti} />
                    <div className="row">
                        <div className="col-3 p-0 d-flex justify-content-center">
                            <img src={noti.user.profileImage || defaultProfile} alt="img" width={45} height={45} style={{ objectFit: 'cover', borderRadius: '100%' }}
                                onError={(e) => {
                                    e.target.onerror = null;  // Prevents endless loop in case fallback image also fails
                                    e.target.src = defaultProfile;  // Replace with default image on error
                                }}
                            />
                        </div>
                        <div className="col-8 p-0">
                            <h5 className="fw-600 w-100">
                                {noti.user.firstName || noti.user.lastName ?
                                    `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                    "Invalid user"}
                            </h5>
                            <h6 className="fw-600"> sent you a friend request.</h6>
                        </div>
                    </div>
                </>
            );

        case 'accept':
            return (
                <>
                    <TopNavNoti noti={noti} />
                    <div className="row">
                        <div className="col-3 p-0 d-flex justify-content-center">
                            <img src={noti.user.profileImage || defaultProfile} alt="img" width={45} height={45} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                        </div>
                        <div className="col-8 p-0">
                            <h5 className="fw-600 w-100">
                                {noti.user.firstName || noti.user.lastName ?
                                    `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                    "Invalid user"}
                            </h5>
                            <h6 className="fw-600">accept your friend request.</h6>
                        </div>
                    </div>
                </>
            );

        case 'comment':
            return (
                <>
                    <TopNavNoti noti={noti} />
                    <div className="row">
                        <div className="col-3 p-0 d-flex justify-content-center">
                            <img src={noti.user.profileImage || defaultProfile} alt="img" width={45} height={45} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                        </div>
                        <div className="col-8 p-0">
                            <h5 className="fw-600 w-100">
                                {noti.user.firstName || noti.user.lastName ?
                                    `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                    "Invalid user"}
                            </h5>
                            <h6 className="fw-600">commented on your post.</h6>
                            <h6 className="fw-600">{`"${noti.content}"`}</h6>
                        </div>
                    </div>
                </>
            )

        case 'create_round':
            return (
                <>
                    <TopNavNoti noti={noti} />
                    <div className="row">
                        <div className="col-3 p-0 d-flex justify-content-center">
                            <img src={noti.user.profileImage || defaultProfile} alt="img" width={45} height={45} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                        </div>
                        <div className="col-8 p-0">
                            <h5 className="fw-600 w-100">
                                {noti.user.firstName || noti.user.lastName ?
                                    `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                    "Invalid user"}
                            </h5>
                            <h6 className="fw-600">created round with you.</h6>
                            <h6 className="fw-600">{`"${noti.content}"`}</h6>
                        </div>
                    </div>
                </>
            )

        case 'join_round':
            return (
                <>
                    <TopNavNoti noti={noti} />
                    <div className="row">
                        <div className="col-3 p-0 d-flex justify-content-center">
                            <img src={noti.user.profileImage || defaultProfile} alt="img" width={45} height={45} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                        </div>
                        <div className="col-8 p-0">
                            <h5 className="fw-600 w-100">
                                {noti.user.firstName || noti.user.lastName ?
                                    `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                    "Invalid user"}
                            </h5>
                            <h6 className="fw-600">request to join round with you.</h6>
                            <h6 className="fw-600">{`"${noti.content}"`}</h6>
                        </div>
                    </div>
                </>
            )

        case 'change_schedule_time':
            return (
                <>
                    <TopNavNoti noti={noti} />
                    <div className="row">
                        <div className="col-3 p-0 d-flex justify-content-center">
                            <img src={noti.user.profileImage || defaultProfile} alt="img" width={45} height={45} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                        </div>
                        <div className="col-8 p-0">
                            <h5 className="fw-600 w-100">
                                {noti.user.firstName || noti.user.lastName ?
                                    `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                    "Invalid user"}
                            </h5>
                            <h6 className="fw-600 mb-2">
                                "{noti.content}" changed schedule time <br />
                                from <strong>{moment.unix(noti.fromDate).format('DD/MM/YY HH:mm')}</strong> to <strong>{moment.unix(noti.toDate).format('DD/MM/YY HH:mm')}</strong>
                            </h6>
                        </div>
                    </div>
                </>
            )

        case 'confirmRoundRequest':

            return (
                <>
                    <TopNavNoti noti={noti} />
                    <div className="row">
                        <div className="col-3 p-0 d-flex justify-content-center align-items-center">
                            <img src={noti.user.profileImage || defaultProfile} alt="img" width={45} height={45} style={{ objectFit: 'cover', borderRadius: '100%' }} />
                        </div>
                        <div className="col-8 p-0">
                            <h5 className="fw-600 w-100">
                                {noti.user.firstName || noti.user.lastName ?
                                    `${noti?.user?.firstName} ${noti?.user?.lastName}` :
                                    "Invalid user"}
                            </h5>
                            <h6 className="fw-600 mb-2">confirm to join round.</h6>
                            <button
                                className="p-1 lh-20 w75 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                                onClick={() => handleTojoin(noti.roundId)}

                            >
                                Join
                            </button>
                        </div>
                    </div>
                </>
            )
        default:
            return (
                <div>
                    <strong>Unknown Notification Type</strong>
                </div>
            );
    }
};

function TopNavNoti({ noti }) {
    return (
        <div className="top-noti">
            {!noti.isRead ? <i className="bi bi-circle-fill red-dot"></i> : <div></div>
            }
            <div className="text-muted">{moment(noti.timestamp * 1000).fromNow()}</div>
        </div>
    )
}

