import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { storage } from "../../components/firebase/FirebaseConfig";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../components/redux/feature/user/userSlice";
import { useAuth } from "../../components/auth/UserContext";

import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useLocation, useNavigate } from "react-router-dom";

import clsx from "clsx";

import { InputGroup, Form, FormGroup } from "react-bootstrap";

import ImageCropper from "../../components/ImageCropper";

import imageCompression from "browser-image-compression";
import { ReactSVG } from "react-svg";
import MyQRCodeModal from "../addfriend/MyQRCodeModal";
import { imageConverter } from "../../components/imageConverter";

const defaultProfile =
  "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742";

function Account() {
  const userData = useSelector((state) => state.user.data);
  const status = useSelector((state) => state.user.status);
  const error = useSelector((state) => state.user.error);
  const dispatch = useDispatch();
  const { handleUpdateUserSendBird } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);
  const [failed, setFailed] = useState(false);

  const succeedFlag = useRef(null);
  const [loading, setLoading] = useState(false);

  const [profileImageChanged, setProfileImageChanged] = useState(null);
  const [profileImage, setProfileImage] = useState(userData?.profileImage);
  const [croppedModal, setCroppedModal] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isCoppied, setIsCoppied] = useState(false);

  const [QRCodeModal, setQRCodeModal] = useState(false);

  async function handleSave(e) {
    e.preventDefault();

    const uid = userData.uid;
    const [year, month, day] = document
      .getElementById("dateBirth")
      .value.split("-");

    const formData = {
      dooNineId: document.getElementById("dooNine").value,
      firstName: document.getElementById("firstname").value,
      lastName: document.getElementById("lastname").value,
      mobile: document.getElementById("mobile-num").value,
      role: document.getElementById("role").value,
      gender: document.getElementById("gender").value,
      birthDate: {
        day: day || null,
        month: month || null,
        year: year || null,
      },
    };

    const nickname = `${formData.firstName} ${formData.lastName}`;

    if (croppedImage) {
      setLoading(true);
      const uploaded = await uploadImage(profileImage, uid);
      if (uploaded) {
        formData.profileImage = uploaded;
      }
    }
    const updatedSendbirdName = await handleUpdateUserSendBird(
      nickname,
      formData.profileImage || null,
      userData.dooNineId
    );
    if (!updatedSendbirdName.success) {
      console.error("Update send profile failed", updatedSendbirdName.error);
    } else {
      dispatch(updateUser(uid, formData));
    }
  }

  function handleCopy(e) {
    e.preventDefault();
    const dooNine_text = document.getElementById("dooNine").value;
    navigator.clipboard.writeText(dooNine_text);
    setIsCoppied(true);

    setTimeout(() => {
      setIsCoppied(false);
    }, 1000);
  }

  function handleQRCode(e) {
    e.preventDefault();
    setQRCodeModal(true);
  }

  useEffect(() => {
    if (status === "succeeded" && !succeedFlag.current) {
      succeedFlag.current = true;
      return;
    }

    if (succeedFlag.current && status === "succeeded" && !error) {
      setLoading(false);
      setChecked(true);

      const savedTimeout = setTimeout(() => {
        setChecked(false);
      }, 1000);

      return () => {
        clearTimeout(savedTimeout);
      };
    }

    if (status === "failed" || error) {
      setLoading(false);
      setFailed(true);
      console.error(error);

      const failedTimeout = setTimeout(() => {
        setFailed(false);
      }, 1000);

      return () => {
        clearTimeout(failedTimeout);
      };
    }
  }, [status]);

  const handleImageChange = (e) => {
    handleImage(e);
    // Reset the input value to allow selecting the same file again
    e.target.value = null;
  };

  async function handleImage(e) {
    let file = e.target.files[0];
    if (file) {
      setProfileImageChanged(null)
      const convertedImage = await imageConverter(file)
      // console.log(file);
      setProfileImageChanged(URL.createObjectURL(convertedImage));
      // setProfileImage(file);  
      showCoppedModal();
    }
  }
  const handleCroppedImage = (file, url) => {
    setCroppedImage(url);
    setProfileImage(file);
  };
  const showCoppedModal = () => setCroppedModal(true);
  const hideCroppedModal = () => setCroppedModal(false);

  async function uploadImage(file, name) {

    try {
      let convertedFile = await imageConverter(file);
      const compressedFile = await imageCompression(convertedFile, {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });

      const storageRef = ref(storage, `profile_image/${name}.jpg`);
      await uploadBytes(storageRef, compressedFile);
      const downloadURL = await getDownloadURL(storageRef);
      setProfileImageChanged(downloadURL);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  }

  useEffect(() => {
    if (userData) {
      document.getElementById("gender").value = userData?.gender || "no-select";
      document.getElementById("role").value = userData?.role || "no-select";
      document.getElementById("dateBirth").value = `${userData?.birthDate?.year || "YYYY"
        }-${(userData?.birthDate?.month || "MM").toString().padStart(2, "0")}-${(
          userData?.birthDate?.day || "DD"
        )
          .toString()
          .padStart(2, "0")}`;
    }
  }, [userData]);

  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="middle-wrap">
              <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                  <Link to="/defaultsettings" className="d-inline-block mt-2">
                    <i className="ti-arrow-left font-sm text-white"></i>
                  </Link>
                  <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">
                    Account Details
                  </h4>
                </div>
                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                  <div className="row justify-content-center">
                    <div className="col-lg-4 text-center">
                      <figure className="avatar ms-auto me-auto mb-0 mt-2 w175 position-relative">
                        {/* <img src={profileImageChanged || userData?.profileImage || defaultProfile} alt="avater" className="shadow-xs rounded-3 w-100 border border-1 p-2" /> */}
                        <img
                          src={
                            croppedImage ||
                            userData?.profileImage ||
                            defaultProfile
                          }
                          alt="avatar"
                          className="shadow-xs rounded-3 w-100 border border-1 p-2"
                          onError={(e) => (e.target.src = defaultProfile)}
                        />
                        <button
                          className="btn btn-light position-relative rounded-circle shadow-md p-0"
                          style={{ right: 0, top: -15 }}
                          onClick={() =>
                            document.getElementById("image-input").click()
                          }
                        >
                          <ReactSVG
                            src="assets/images/dooNineIconV2/others/profile-edit-image.svg"
                            className="w40"
                          />
                        </button>
                      </figure>
                      <input
                        type="file"
                        name="image-input"
                        id="image-input"
                        className="d-none"
                        accept="image/*, .heic, .heif, image/heic, image/heif"
                        onChange={handleImageChange}
                      />
                      <h2 className="fw-700 font-sm text-grey-900 mt-3">
                        {userData?.firstName || userData?.firstname}{" "}
                        {userData?.lastName || userData?.lastname}
                      </h2>
                    </div>
                  </div>

                  <form onSubmit={handleSave}>
                    <div className="row">
                      <FormGroup className="col-lg-6">
                        <Form.Label className="mont-font fw-600 font-xsss mb-2">
                          Doonine ID
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            defaultValue={userData?.dooNineId}
                            disabled={true}
                            id="dooNine"
                          />
                          {/* <Button variant="light" id="button-addon2" onClick={() => setEditID(!editID)}>
                                                    <i className=" feather-edit fs-3"></i>
                                                </Button> */}
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="col-lg-6">
                        <div className="mb-3 d-flex justify-content-around mt-sm-4">
                          <button
                            className="btn btn-light theme-dark-bg w125 border border-0 my-1 mt-sm-2 d-flex justify-content-center align-items-center"
                            style={{ height: 50 }}
                            onClick={handleCopy}
                          >
                            <h5 className="m-0 fw-600">Copy ID</h5>
                            {isCoppied && (
                              <i className="bi bi-check fs-1 text-success"></i>
                            )}
                          </button>

                          <button
                            className="btn btn-light theme-dark-bg w125 border border-0 my-1 mt-sm-2"
                            style={{ height: 50 }}
                            onClick={handleQRCode}
                          >
                            <h5 className="m-0 fw-600">QR Code</h5>
                          </button>
                        </div>
                      </FormGroup>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              userData?.firstName || userData?.firstname
                            }
                            id="firstname"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              userData?.lastName || userData?.lastname
                            }
                            id="lastname"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            defaultValue={userData?.email}
                            id="email"
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Phone
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            defaultValue={userData?.mobile}
                            id="mobile-num"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Role
                          </label>
                          <select
                            name="role"
                            id="role"
                            className="form-select"
                            disabled={
                              !userData?.role ||
                                userData?.role === "" ||
                                userData?.role === "no-select"
                                ? false
                                : true
                            }
                          >
                            <option value="no-select">No select</option>
                            <option value="player">Player</option>
                            <option value="caddie">Caddie</option>
                          </select>
                          {!userData?.role ||
                            userData?.role === "" ||
                            userData?.role === "no-select" ? (
                            <span className="text-danger">
                              Please select role
                            </span>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label className="mont-font fw-600 font-xsss mb-2">
                            Gender
                          </label>
                          <select
                            name="gender"
                            id="gender"
                            className="form-select"
                          >
                            <option value="no-select">No select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-3">
                        <div className="form-group">
                          <label
                            htmlFor="dateBirth"
                            className="mont-font fw-600 font-xsss mb-2"
                          >
                            Date of Birth
                          </label>
                          <input
                            type="date"
                            name="dateBirth"
                            id="dateBirth"
                            className="form-control"
                            style={{ lineHeight: "normal" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="d-flex align-items-center">
                      <button
                        type="submit"
                        className={clsx(
                          "btn bg-current text-white fw-500 fs-3 py-2 px-3",
                          {
                            disabled: status === "loading" || loading,
                          }
                        )}
                        style={{ minWidth: "6rem" }}
                      >
                        {status === "loading" || loading ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="sr-only"></span>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </button>
                      <i
                        className={clsx(
                          "feather-check fw-bold text-success mx-2",
                          {
                            "d-none": !checked,
                          }
                        )}
                        style={{ fontSize: 35 }}
                      ></i>
                      <i
                        className={clsx("feather-x fw-bold text-danger mx-2", {
                          "d-none": !failed,
                        })}
                        style={{ fontSize: 35 }}
                      ></i>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popupchat />
      <Appfooter />
      <ImageCropper
        show={croppedModal}
        hide={hideCroppedModal}
        profileImage={profileImageChanged}
        croppedImage={handleCroppedImage}
      />

      <MyQRCodeModal
        QRCodeModal={QRCodeModal}
        setQRCodeModal={setQRCodeModal}
      ></MyQRCodeModal>
    </>
  );
}

export default Account;
