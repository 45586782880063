import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

import { ReactSVG } from "react-svg";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

import { handleUploadCoverImage } from "./handleUploadCoverImage";

function ChatRoomGroupSetting({
  showGroupSettingModal,
  setShowGroupSettingModal,
}) {
  const userData = useSelector((state) => state.user.data);
  const [channelId, setChannelId] = useState();
  const navigate = useNavigate();

  const [groupChannelName, setGroupChannelName] = useState("");

  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isOperator, setIsOperator] = useState(false);

  const [checkUpdate, setCheckUpdate] = useState({
    name: false,
    image: false,
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const channelId_ = params.get("channelId");
    setChannelId(channelId_);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}?&show_member=true`,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );

        setGroupChannelName(response.data.name);
        setImageUrl(response.data.cover_url);

        const isOperator = response.data.operators.some(
          (operator) => operator.user_id === userData.dooNineId
        );
        setIsOperator(isOperator);
      } catch (error) {
        console.log(error);
      }
    }
    if (channelId) {
      fetchData();
    }
  }, [channelId, showGroupSettingModal]);

  const handleCloseModal = () => {
    setGroupChannelName("");
    setImageUrl(null);
    setShowGroupSettingModal(false);
    setCheckUpdate({ name: false, image: false });
  };

  // const handleUpdateChannel = async () => {
  //   if (checkUpdate.name && checkUpdate.image) {
  //     try {
  //       const downloadUrl = await handleUploadCoverImage(channelId, imageFile);
  //       try {
  //         const response = await axios.put(
  //           `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}`,
  //           {
  //             cover_url: downloadUrl,
  //             name: groupChannelName,
  //           },
  //           {
  //             headers: {
  //               "Content-Type": "application/json; charset=utf8",
  //               "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
  //             },
  //           }
  //         );
  //         if (response.status !== 200) {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Error",
  //             text: "Group chat update failed",
  //             confirmButtonColor: "#ee3d7f",
  //           });
  //         } else {
  //           Swal.fire({
  //             icon: "success",
  //             title: "Success",
  //             text: "Group chat updated successfully",
  //             confirmButtonColor: "#ee3d7f",
  //           }).then(() => {
  //             //refresh page
  //             // window.location.reload();
  //           });
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } catch (error) {
  //       console.error("Error during image upload process:", error);
  //       throw error; // Re-throw error to be handled by the caller if necessary
  //     }
  //   } else if (checkUpdate.name && !checkUpdate.image) {
  //     try {
  //       const response = await axios.put(
  //         `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}`,
  //         {
  //           name: groupChannelName,
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json; charset=utf8",
  //             "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
  //           },
  //         }
  //       );
  //       if (response.status !== 200) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Error",
  //           text: "Group chat name update failed",
  //           confirmButtonColor: "#ee3d7f",
  //         });
  //       } else {
  //         Swal.fire({
  //           icon: "success",
  //           title: "Success",
  //           text: "Group chat name updated successfully",
  //           confirmButtonColor: "#ee3d7f",
  //         }).then(() => {
  //           //refresh page
  //           // window.location.reload();
  //         });
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else if (!checkUpdate.name && checkUpdate.image) {
  //     try {
  //       const downloadURL = await handleUploadCoverImage(channelId, imageFile);

  //       try {
  //         const response = await axios.put(
  //           `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}`,
  //           {
  //             cover_url: downloadURL,
  //           },
  //           {
  //             headers: {
  //               "Content-Type": "application/json; charset=utf8",
  //               "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
  //             },
  //           }
  //         );
  //         if (response.status !== 200) {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Error",
  //             text: "Group chat update failed",
  //             confirmButtonColor: "#ee3d7f",
  //           });
  //         } else {
  //           Swal.fire({
  //             icon: "success",
  //             title: "Success",
  //             text: "Group chat updated successfully",
  //             confirmButtonColor: "#ee3d7f",
  //           }).then(() => {
  //             //refresh page
  //             // window.location.reload();
  //           });
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     } catch (error) {
  //       console.error("Error during image upload process:", error);
  //       throw error; // Re-throw error to be handled by the caller if necessary
  //     }
  //   }
  // };

  const handleUpdateChannel = async () => {
    try {
      Swal.fire({
        title: "Loading...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      let updatedFields = {};

      // Check if the name needs to be updated
      if (checkUpdate.name) {
        updatedFields.name = groupChannelName;
      }

      // Check if the image needs to be updated
      if (checkUpdate.image) {
        const downloadUrl = await handleUploadCoverImage(channelId, imageFile);
        updatedFields.cover_url = downloadUrl;
      }

      // Only make the API call if there are fields to update
      if (Object.keys(updatedFields).length > 0) {
        const response = await axios.put(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}`,
          updatedFields,
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Group chat updated successfully",
            confirmButtonColor: "#ee3d7f",
          }).then(() => {
            // Refresh the page or perform other actions
            window.location.reload();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Group chat update failed",
            confirmButtonColor: "#ee3d7f",
          });
        }
      }
    } catch (error) {
      console.error("Error updating the group channel:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while updating the group chat",
        confirmButtonColor: "#ee3d7f",
      });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setImageUrl(URL.createObjectURL(file));
    setCheckUpdate({ ...checkUpdate, image: true });
  };

  const handleRemoveImage = () => {
    setImageUrl(null);
    setCheckUpdate({ ...checkUpdate, image: true });
  };

  const handleDeleteGroupChat = async (channelId) => {
    Swal.fire({
      title: "Do you want to delete this chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}`,

            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
              },
            }
          );

          Swal.fire({
            title: "Delete Channel Success ",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/chat");
          });
        } catch (error) {
          console.error("Error leaving channel:", error);
        }
      }
    });
  };

  return (
    <>
      <Modal
        show={showGroupSettingModal}
        onHide={() => handleCloseModal()}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2
              style={{ display: "inline-block", marginLeft: "15px" }}
              className="fw-700 mb-0 mt-0 font-sm text-grey-700"
            >
              Group setting
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row ps-2 pe-2">
              <div className="mb-4">
                <h4 className="fw-600 mt-3 mb-1">Group Chat Name</h4>
                <input
                  type="text"
                  name="round-name"
                  id="round-name"
                  className="form-control"
                  value={groupChannelName}
                  onChange={(e) => {
                    setGroupChannelName(e.target.value);
                    setCheckUpdate({ ...checkUpdate, name: true });
                  }}
                />
              </div>

              <div className="mb-2">
                <h4 className="fw-600 mb-1">Group Chat Image Cover</h4>
                <input
                  type="file"
                  name="round-cover-input"
                  id="round-cover-input"
                  accept="image/*"
                  className="d-none"
                  onChange={(e) => handleImageChange(e)}
                />
                {imageUrl ? (
                  <div
                    className="position-relative p-1 border"
                    style={{ width: 150 }}
                  >
                    <img
                      src={imageUrl}
                      alt="cover-img"
                      className="w-100"
                      style={{ objectFit: "contain" }}
                    />
                    <ReactSVG
                      src="assets/images/dooNineIconV2/others/post-close.svg"
                      width={25}
                      height={25}
                      wrapper="svg"
                      className="position-absolute pointer"
                      style={{ top: 2, right: 2 }}
                      onClick={handleRemoveImage}
                    />
                  </div>
                ) : (
                  <ReactSVG
                    src="assets/images/dooNineIconV2/others/create_Post_Add_Photo.svg"
                    wrapper="svg"
                    width={80}
                    height={80}
                    className="add-round-cover-btn"
                    onClick={() =>
                      document.getElementById("round-cover-input").click()
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {/* Delete Group Chat Button */}
          <button
            disabled={isOperator ? false : true}
            title="Only operators can delete the group chat"
            className="btn text-white mx-1 fs-5"
            style={{ backgroundColor: "red" }}
            onClick={() => handleDeleteGroupChat(channelId)}
          >
            Delete Group Chat
          </button>

          <div className="d-flex">
            {/* Update Button */}
            <button
              className="btn bg-current text-white mx-1 fs-5"
              onClick={() => handleUpdateChannel()}
            >
              Update
            </button>

            {/* Close Button */}
            <button
              className="btn bg-greylight"
              onClick={() => handleCloseModal()}
            >
              <h4 className="fw-600 m-0 text-grey-700 p-1">Close</h4>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChatRoomGroupSetting;
