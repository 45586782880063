import React, { useState, useEffect } from "react";
import LanguageDropdown from "../components/language/dropdown/DropDownLang";
import { useAuth } from "../components/auth/UserContext";
import LoadCircle from "../components/LoadCircle";

const logo = "assets/images/banner/doonine_revB.png";
const text = "assets/images/banner/doonine_text_2.png";

const Forgot = () => {
  const { emailResetPassword } = useAuth();

  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState("");
  const [timeLeft, setTimeLeft] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email || email.trim() === "") return;

    setError("");
    setIsSent(false);
    setLoading(true);

    const response = await emailResetPassword(email);

    if (response) {
      setIsSent(true);
      setTimeLeft(60);

    } else {
      setError(response);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (timeLeft <= 0) {
      setIsSent(false);
      return;
    }
    // Set up the interval
    if (isSent) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }

  }, [timeLeft, isSent]);

  return (
    <>
      <div className="main-wrap">
        <div className="nav-header bg-transparent shadow-none border-0">
          <div className="nav-top w-100 d-flex justify-content-between">
            <a href="/">
              <div className="log-container d-flex align-items-center">
                <img src={logo} alt="logo" width={55} />
                <img className='d-none d-md-block' src={text} alt='logo' width={180} />
                <img className='d-md-none d-block' src={text} alt='logo' width={130} />
              </div>
            </a>
            {/* <button className="nav-menu me-0 ms-auto"></button> */}

            <LanguageDropdown />
            {/* <a href="/login" className="header-btn d-none d-lg-block bg-dark fw-500 text-white font-xsss p-3 ms-auto w100 text-center lh-20 rounded-xl">Login</a>
                        <a href="/register" className="header-btn d-none d-lg-block bg-current fw-500 text-white font-xsss p-3 ms-2 w100 text-center lh-20 rounded-xl">Register</a> */}
          </div>
        </div>

        <div className="row">
          <div className="col-xl-7 d-none d-xl-block p-0 vh-100 bg-image-cover bg-no-repeat"
            style={{ backgroundImage: `url('assets/images/login/login_resized.jpg')` }}>
          </div>

          <div className="col-xl-5 vh-100 align-items-center d-flex bg-white rounded-3 overflow-hidden">
            <div className="card shadow-none border-0 ms-auto me-auto login-card">
              <div className="card-body rounded-0 text-left">
                <h2 className="fw-700 display1-size display2-md-size mb-4">
                  Forget <br />
                  Password
                </h2>
                <div className="form-group icon-input">
                  <i className="font-sm feather-mail text-grey-500 pe-0"></i>
                  <input
                    type="email"
                    className="style2-input ps-5 form-control text-grey-900 font-xsss fw-600"
                    value={email}
                    placeholder={"Email"}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                  />
                </div>
                <span className="text-danger fw-500 d-block">{error}</span>
                {isSent && (
                  <span className="fw-600 my-2 d-block text-success">
                    Password reset email sent, Check your inbox.
                  </span>
                )}
                <button
                  className="btn btn bg-current text-white w-100 p-2 fs-3 d-flex align-items-center justify-content-center mt-2"
                  id="login-button"
                  style={{ minHeight: "3rem" }}
                  onClick={() => handleSubmit()}
                  disabled={timeLeft > 0 ? true : false}
                >
                  {loading ?
                    <LoadCircle size="sm" />
                    :
                    <>
                      Submit{timeLeft > 0 ? ` (${timeLeft})` : ""}
                    </>
                  }

                </button>
                <span className="fw-600 font-xsss text-grey-700 mt-1 d-block">
                  Aleady have account ?
                  <a href="/login" className="fs-4 fw-600 ms-1">
                    Login
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgot;
