import imageCompression from "browser-image-compression";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../components/firebase/FirebaseConfig";

export const handleUploadCoverImage = async (channelId, file) => {
  if (!file) {
    const urls = [
      "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/chat_cover%2Fmain_image%2Fcover_11.png?alt=media&token=37040bd1-e29f-458e-ad47-7b0d293f05e6",
      "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/chat_cover%2Fmain_image%2Fcover_12.png?alt=media&token=7f02b138-7adf-4709-8c35-51bf83e048e7",
      "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/chat_cover%2Fmain_image%2Fcover_13.png?alt=media&token=3dfdb5e2-cdf5-473d-bd19-d13deb5cba90",
      "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/chat_cover%2Fmain_image%2Fcover_14.png?alt=media&token=6b60597e-02e2-4205-86f3-fa7e98069502",
      "https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/chat_cover%2Fmain_image%2Fcover_15.png?alt=media&token=194b7d4d-6a6b-4cde-b8f0-9b665f1cca55",
    ];

    const randomIndex = Math.floor(Math.random() * urls.length);
    return urls[randomIndex];
  }

  try {
    // Compress the image
    const compressedFile = await imageCompression(file, {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    });

    const storageRef = ref(
      storage,
      `chat_cover/${channelId}/${file.name}_${file?.lastModified}.jpg`
    );
    const uploadTask = uploadBytesResumable(storageRef, compressedFile);

    const downloadURL = await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null, // Skip progress tracking
        (error) => {
          console.error("Upload error:", error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            console.error("Error getting download URL:", error);
            reject(error);
          }
        }
      );
    });

    return downloadURL;
  } catch (error) {
    console.error("Error during image upload process:", error);
    throw error; // Re-throw error to be handled by the caller if necessary
  }
};
