import React, { Fragment, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";

import { ReactSVG } from "react-svg";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

function ChatRoomMember(props) {
  const userData = useSelector((state) => state.user.data);
  const [channelId, setChannelId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const channelId_ = params.get("channelId");
    setChannelId(channelId_);
  }, []);

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleRemoveMemberinGroup = async (value) => {
    Swal.fire({
      title:
        "Remove '" +
        value.firstName +
        " " +
        value.lastName +
        "' from the group?",
      html: `
        <h5 class="fw-600">You are about to remove this member from the group.</h5>
        <h5 class="fw-600">Once removed, they cannot be added back.</h5>
      `,
      icon: "warning",
      confirmButtonColor: "#ee3d7f",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await axios.put(
          "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/" +
            channelId +
            "/leave",
          {
            user_ids: [value.dooNineId],
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );

        if (response.status == 200) {
          props.handleShowMember();
          Swal.fire({
            icon: "success",
            title: "Remove member success",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          });
        } else {
          console.log(response);

          Swal.fire({
            icon: "error",
            title: "Remove member failed",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const handleRemoveInviteMemberinGroup = async (value) => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await axios.put(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/decline`,
        { user_id: value.dooNineId },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      const response2 = await axios.post(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelId}/messages`,
        {
          message_type: "MESG",
          user_id: userData.dooNineId,
          message: `${userData.firstName} ${userData.lastName} cancel invitation ${value.firstName} ${value.lastName} to this group chat.`,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Denied successfully",
        showConfirmButton: false,
        timer: 1000,
      });
      props.handleShowMember();
    } catch (error) {
      if (error.response) {
        console.error("Failed to join group chat:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const invitedCount = props.groupMemberData?.filter(
    (value) => value.stateSendbird === "invited"
  ).length;

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => props.handleCloseModal()}
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2
              style={{ display: "inline-block", marginLeft: "15px" }}
              className="fw-700 mb-0 mt-0 font-sm text-grey-700"
            >
              Group members
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row ps-2 pe-2">
              <h5 className="mt-2 mb-2">
                <strong>Members</strong>
              </h5>
              {props.groupMemberData?.map(
                (value, index) =>
                  value.stateSendbird !== "invited" && (
                    <div key={index} className="col-md-12 col-sm-12 pe-2 ps-2">
                      <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-2">
                        <div className="card-body d-flex p-3">
                          <figure
                            className="avatar mb-0 position-relative z-index-1 me-3"
                            style={{
                              flexShrink: 0,
                              justifyContent: "center",
                              display: "flex",
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              src={value.profileImage}
                              alt={`${value.firstName} ${value.lastName}`}
                              className="bg-image-cover float-left p-1 rounded-circle w-100"
                              style={{
                                cursor: "pointer",
                                objectFit: "cover",
                                backgroundColor:
                                  value.role === "caddie"
                                    ? "#EE3D7F"
                                    : "#4A74E5",
                              }}
                            />
                          </figure>
                          <div className="flex-grow-1">
                            <div
                              className="d-flex"
                              style={{ alignItems: "center" }}
                            >
                              {value.stateSendbird === "invited" && (
                                <h5 className="text-muted font-xsss mt-2 mb-1 me-2">
                                  (Invited)
                                </h5>
                              )}
                              <h4
                                className="fw-700 font-xsss mt-2 mb-1"
                                onClick={() => handleProfileClick(value.uid)}
                                style={{ cursor: "pointer" }}
                              >
                                {value.firstName} {value.lastName}
                                {value.roleSendbird === "operator" &&
                                  " (Operator)"}
                              </h4>

                              {props.isOperator &&
                              value.uid !== userData.uid ? (
                                <i
                                  onClick={() =>
                                    handleRemoveMemberinGroup(value)
                                  }
                                  className="bi bi-trash ms-3"
                                  style={{
                                    fontSize: "18px",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              ) : (
                                value.uid !== userData.uid && (
                                  <i
                                    className="bi bi-trash ms-3"
                                    style={{
                                      fontSize: "18px",
                                      color: "#d3d3d3",
                                    }}
                                  ></i>
                                )
                              )}
                            </div>
                            <div className="icon-container d-flex flex-wrap">
                              {value.role === "caddie" && value.isStar && (
                                <span className="fw-600 d-inline-block z-index-1 ls-3 font-xssss text-white">
                                  <ReactSVG
                                    src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                                    style={{ width: "25px" }}
                                  />
                                </span>
                              )}
                              {value.role === "caddie" && value.isPro && (
                                <span className="fw-600 d-inline-block z-index-1 ls-3 font-xssss text-white">
                                  <ReactSVG
                                    src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                                    style={{ width: "25px" }}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}

              {invitedCount > 0 && (
                <>
                  <h5 className="mt-2 mb-2">
                    <strong>Invite Members</strong>
                  </h5>
                  {props.groupMemberData?.map(
                    (value, index) =>
                      value.stateSendbird === "invited" && (
                        <div
                          key={index}
                          className="col-md-12 col-sm-12 pe-2 ps-2"
                        >
                          <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-2">
                            <div className="card-body d-flex p-3">
                              <figure
                                className="avatar mb-0 position-relative z-index-1 me-3"
                                style={{
                                  flexShrink: 0,
                                  justifyContent: "center",
                                  display: "flex",
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                }}
                              >
                                <img
                                  src={value.profileImage}
                                  alt={`${value.firstName} ${value.lastName}`}
                                  className="bg-image-cover float-left p-1 rounded-circle w-100"
                                  style={{
                                    cursor: "pointer",
                                    objectFit: "cover",
                                    backgroundColor:
                                      value.role === "caddie"
                                        ? "#EE3D7F"
                                        : "#4A74E5",
                                  }}
                                />
                              </figure>
                              <div className="flex-grow-1">
                                <div
                                  className="d-flex"
                                  style={{ alignItems: "center" }}
                                >
                                  <h4
                                    className="fw-700 font-xsss mt-2 mb-1"
                                    onClick={() =>
                                      handleProfileClick(value.uid)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {value.firstName} {value.lastName}
                                    {value.roleSendbird === "operator" &&
                                      " (Operator)"}
                                  </h4>

                                  {props.isOperator &&
                                  value.uid !== userData.uid ? (
                                    <i
                                      onClick={() =>
                                        handleRemoveInviteMemberinGroup(value)
                                      }
                                      className="bi bi-trash ms-3"
                                      style={{
                                        fontSize: "18px",
                                        cursor: "pointer",
                                      }}
                                    ></i>
                                  ) : (
                                    value.uid !== userData.uid && (
                                      <i
                                        className="bi bi-trash ms-3"
                                        style={{
                                          fontSize: "18px",
                                          color: "#d3d3d3",
                                        }}
                                      ></i>
                                    )
                                  )}
                                </div>
                                <div className="icon-container d-flex flex-wrap">
                                  {value.role === "caddie" && value.isStar && (
                                    <span className="fw-600 d-inline-block z-index-1 ls-3 font-xssss text-white">
                                      <ReactSVG
                                        src="assets/icon/CaddieFriendFav-CaddieStar.svg"
                                        style={{ width: "25px" }}
                                      />
                                    </span>
                                  )}
                                  {value.role === "caddie" && value.isPro && (
                                    <span className="fw-600 d-inline-block z-index-1 ls-3 font-xssss text-white">
                                      <ReactSVG
                                        src="assets/icon/CaddieFriendFav-ProCaddie.svg"
                                        style={{ width: "25px" }}
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn bg-current text-white mx-1 fs-5"
            onClick={() => {
              props.handleCloseModal();
              props.setShowGroupInviteModal(true);
            }}
          >
            Invite
          </button>
          <button
            className="btn bg-greylight"
            onClick={() => props.handleCloseModal()}
          >
            <h4 className="fw-600 m-0 text-grey-700 p-1">Close</h4>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChatRoomMember;
