import heic2any from "heic2any";

export async function imageConverter(file) {
    let convertedFile = file;

    // Note** bug is possible with odd file extension
    if (file.type === 'image/heic' || file.type === 'image/heif' || file.type === "") {
        try {
            const heicBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
            convertedFile = new File([heicBlob], file.name.replace(/\.[^/.]+$/, ".jpg"), {
                type: 'image/jpeg',
                lastModified: file.lastModified,
            });
            return convertedFile
        } catch (error) {
            console.error('Error converting HEIC to JPG:', error);
        }
    } else {
        return file
    }
}