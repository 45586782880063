import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  orderBy,
  onSnapshot,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import clsx from "clsx";
import { ReactSVG } from "react-svg";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import { deleteRoundForCreator } from "../module/deleteRound";

function RoundFinish() {
  const [listRound, setListRound] = useState([]);
  const [loading, setLoading] = useState(true);
  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, "round"), orderBy("scheduleTime", "desc")),
      async (userSnapshot) => {
        try {
          setLoading(true);
          let roundDataArray = [];
          let dataArray = [];

          const fetchScoreDetails = async (uid, roundId) => {
            const userQuery = query(
              collection(firestore, "scoreCards"),
              where("userId", "==", uid),
              where("roundId", "==", roundId)
            );
            const caddieQuery = query(
              collection(firestore, "scoreCards"),
              where("caddieId", "==", uid),
              where("roundId", "==", roundId)
            );

            const [userSnapshot, caddieSnapshot] = await Promise.all([
              getDocs(userQuery),
              getDocs(caddieQuery),
            ]);

            let scoreDetails = {};

            userSnapshot.forEach((doc) => {
              scoreDetails = doc.data();
            });

            caddieSnapshot.forEach((doc) => {
              scoreDetails = doc.data();
            });

            return scoreDetails;
          };

          const fetchUserDetails = async (uid) => {
            const q = query(
              collection(firestore, "users"),
              where("uid", "==", uid)
            );
            const userSnapshot = await getDocs(q);
            let userDetails = {};
            userSnapshot.forEach((userDoc) => {
              userDetails = userDoc.data();
            });
            return userDetails;
          };

          for (const roundDoc of userSnapshot.docs) {
            const roundData = roundDoc.data();
            const isCreator = roundData.creator === userData.uid;
            const isUserInList = roundData.userList.some(
              (user) =>
                (user.player?.uid === userData.uid &&
                  user.player?.isJoin === true) ||
                (user.caddie?.uid === userData.uid &&
                  user.caddie?.isJoin === true)
            );

            if (isCreator || isUserInList) {
              const playerUids = roundData.userList
                .map((user) => user.player?.uid || user.caddie?.uid)
                .filter((uid) => uid !== undefined);

              if (playerUids.length > 0) {
                const scoreDetailsArray = await Promise.all(
                  playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
                );

                roundData.userList = roundData.userList.map((user) => {
                  const activeUser = user.player ?? user.caddie; // ใช้ player ถ้ามี, ถ้าไม่มีใช้ caddie
                  if (activeUser?.uid) {
                    const playerScore = scoreDetailsArray.find(
                      (details) =>
                        details.userId === activeUser.uid ||
                        details.caddieId === activeUser.uid
                    );
                    const status = playerScore ? playerScore.status : undefined;
                    return {
                      ...user,
                      player: { ...user.player, status },
                      caddie: { ...user.caddie, status },
                    };
                  }
                  return user;
                });

                const isRoundStatus = roundData.userList.some(
                  (user) =>
                    (user.player?.uid === userData.uid &&
                      (user.player?.status === "finished" ||
                        user.player?.status === "unfinished")) ||
                    (user.caddie?.uid === userData.uid &&
                      (user.caddie?.status === "finished" ||
                        user.caddie?.status === "unfinished"))
                );

                if (isRoundStatus && roundData.status !== "deleted") {
                  roundDataArray.push({
                    ...roundData,
                    roundId: roundDoc.id,
                  });
                }
              }
            }
          }

          for (const round of roundDataArray) {
            let holesCourse = "";
            if (round.holesCourse) {
              const courseHolesCollection = collection(
                firestore,
                "courseHoles"
              );
              const q = doc(courseHolesCollection, round.holesCourse);
              const courseHolesSnapshot = await getDoc(q);
              if (courseHolesSnapshot.exists) {
                holesCourse = courseHolesSnapshot.data().courseHoleName;
              }
            }

            const courseQuery = query(
              collection(firestore, "courses"),
              where("id", "==", round.course)
            );
            const { time, day, month } = epochToDateTime(round.scheduleTime);

            let userList = round.userList;
            let userDataArray = [];

            for (let userObj of userList) {
              const { caddie, player, isReview } = userObj;

              if (player?.uid) {
                const playerData = await fetchUserDetails(player.uid);
                const combinedPlayerData = {
                  ...playerData,
                  isCreator: player.isCreator,
                  isJoin: player.isJoin,
                  isReview: isReview,
                };
                userDataArray.push(combinedPlayerData);
              }

              if (caddie?.uid) {
                const caddieData = await fetchUserDetails(caddie.uid);
                const combinedCaddieData = {
                  ...caddieData,
                  isCreator: caddie.isCreator,
                  isJoin: caddie.isJoin,
                  isReview: isReview,
                };
                userDataArray.push(combinedCaddieData);
              }
            }

            const courseSnapshot = await getDocs(courseQuery);
            courseSnapshot.forEach((doc2) => {
              const courseData = doc2.data();
              dataArray.push({
                ...round,
                courseInfo: courseData,
                time: time,
                day: day,
                month: month,
                userData: userDataArray,
                holesCourse: holesCourse,
              });
            });
          }

          setListRound(dataArray);
        } catch (error) {
          console.error("Error fetching data: ", error);
        } finally {
          setLoading(false); // Set loading to false after data is fetched
        }
      }
    );

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, [userData]);

  // console.log(listRound);

  const handleDeleteRound = async (roundId) => {
    try {
      await deleteRoundForCreator(roundId);
    } catch (error) {
      console.error("Error deleted round:", error);
    }
  };

  const handleLinkToCourse = (courseId) => {
    navigate(`/course-detail?courseId=${courseId}`);
  };

  const [selectedHoles, setSelectedHoles] = useState("allRounds"); // 'all' means showing all rounds

  const handleSelection = (e) => {
    setSelectedHoles(e.target.value);
  };

  const filteredRounds = listRound.filter((round) => {
    if (selectedHoles === "18Holes") {
      return round.holes === "18 holes";
    } else if (selectedHoles === "9Holes") {
      return round.holes === "9 holes";
    }
    return true; // Show all rounds when 'All Rounds' is selected
  });

  const handleChat = async (channelUrl) => {
    navigate("/chat-room?channelId=" + channelUrl);
  };

  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl">
        <Card.Body className="main-player-stat">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center mb-2">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{
                    height: "40px",
                  }}
                  onChange={handleSelection}
                >
                  <option selected value="allRounds">
                    All Rounds
                  </option>
                  <option value="18Holes">18 Holes Finished</option>
                  <option value="9Holes">9 Holes Finished</option>
                </select>

                {/* Icon for filtering */}
                <div
                  style={{
                    cursor: "pointer",
                    height: "40px",
                    width: "40px",
                  }}
                  className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
                >
                  <i className="feather-filter font-xss text-grey-500"></i>
                </div>
              </div>

              {filteredRounds.length > 0 ? (
                filteredRounds.map((round, index) => (
                  <div key={index} className="create-round-req-main">
                    <section className="create-round-req-head">
                      <div className="pointer">
                        <h4 className="fw-600">{round.roundName}</h4>
                        <br />
                        <h6 className="fw-600 text-muted">
                          {`${round.holes} (${round.holesCourse} ), ${round.gameMode}(${round.scoring})`}
                        </h6>
                      </div>
                      <div className="d-flex flex-wrap-reverse justify-content-end align-items-end">
                        <ReactSVG
                          src="assets/images/dooNineIconV2/chat-SL.svg"
                          wrapper="svg"
                          style={{ height: 40, width: 40 }}
                          className={clsx("pointer", {
                            "d-none": round.groupChat !== "create",
                          })}
                          onClick={() => handleChat(round.channelUrl)}
                        />
                        <Dropdown className="drodownFriend" drop="down">
                          <Dropdown.Toggle
                            as="span" // Use a span element for the wrapper
                            id="dropdown-basic"
                            style={{ cursor: "pointer", zIndex: 1000 }}
                          >
                            <ReactSVG
                              src="assets/images/dooNineIconV3/create-round/createRound-menu.svg"
                              wrapper="svg"
                              className="wizrd-more-btn"
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu as={CustomDropdown}>
                            <Dropdown.Item
                              href={`/score?round=${round.roundId}`}
                            >
                              Resume Round
                            </Dropdown.Item>
                            <Dropdown.Item
                              href={`/leader-board?round=${round.roundId}`}
                            >
                              Leader Board
                            </Dropdown.Item>
                            <Dropdown.Item href="#/game-mode" disabled>
                              Game mode
                            </Dropdown.Item>
                            <Dropdown.Item href="#/competitor" disabled>
                              Competitor
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              href={`/p-and-c-round?round=${round?.roundId}`}
                              disabled
                            >
                              Player & Caddie
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => handleLinkToCourse(round.course)}
                            >
                              Course
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item disabled>Edit Round</Dropdown.Item>
                            <Dropdown.Item disabled>
                              Invitation Link
                            </Dropdown.Item>
                            {round.userData.some(
                              (user) =>
                                user.uid === userData.uid &&
                                user.isCreator === true
                            ) ? (
                              <Dropdown.Item
                                onClick={() => handleDeleteRound(round.roundId)}
                              >
                                Delete Round
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item href="#/leave-round" disabled>
                                Leave Round
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </section>
                    <section
                      className="create-round-req-body p-2 mb-2 pointer"
                      //   onClick={() => handleChatandDateClick()}
                      style={{
                        backgroundImage: round.coverImage
                          ? typeof round.coverImage === "string"
                            ? `url(${round.coverImage})`
                            : `url(${URL.createObjectURL(round.coverImage)})`
                          : "none",
                        height: round.coverImage ? "" : "inherit",
                      }}
                    >
                      <div
                        className="req-course-data px-0 row"
                        style={{
                          backgroundColor: round.coverImage ? "" : "#fff",
                        }}
                      >
                        <div
                          className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center"
                          onClick={() => handleLinkToCourse(round.course)}
                        >
                          <img
                            src={round.courseInfo.profileImage}
                            alt="course-profile"
                            width={70}
                            height={70}
                            className="rounded-circle my-2 d-sm-block d-none"
                          />
                          {/* mobile */}
                          <img
                            src={round.courseInfo.profileImage}
                            alt="course-profile"
                            width={50}
                            height={50}
                            className="rounded-circle my-2 d-sm-none d-block"
                          />
                        </div>
                        <div
                          className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center"
                          onClick={() => handleLinkToCourse(round.course)}
                        >
                          <div>
                            {/* desktop */}
                            <span className="fw-600 fs-4 d-sm-block d-none">
                              {round.courseInfo.courseName}
                            </span>
                            {/* mobile */}
                            <span className="fw-600 fs-5 d-sm-none d-block">
                              {round.courseInfo.courseName}
                            </span>
                            {/* mobile */}
                            <span className="fw-600 fs-5 d-sm-none d-block">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>

                            <span className="fw-600 fs-6 d-sm-block d-none">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                          </div>
                        </div>
                        <div className="col-sm-2 col-3 px-0 px-sm-20">
                          <div
                            className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                            style={{ backgroundColor: "#212529" }}
                            // onClick={() => handleChatandDateClick()}
                          >
                            <h4 className="fw-600 text-white text-center">
                              {round.month}
                            </h4>
                            <h1 className="fw-700 text-white text-center">
                              {round.day}
                            </h1>
                            <div className="bg-white text-center w-100">
                              <span
                                className="fw-600 fs-4"
                                style={{ color: "#212529" }}
                              >
                                {round.time}
                              </span>
                            </div>
                            {/* {round.teeTime === "finished" ? (
                              <>
                                <h4 className="fw-600 text-white text-center">
                                  {round.month}
                                </h4>
                                <h1 className="fw-700 text-white text-center">
                                  {round.day}
                                </h1>
                                <div className="bg-white text-center w-100">
                                  <span
                                    className="fw-600 fs-4"
                                    style={{ color: "#212529" }}
                                  >
                                    {round.time}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <div className="bg-white text-center w-100">
                                <span
                                  className="fw-600 fs-4"
                                  style={{ color: "#212529" }}
                                >
                                  Start Now
                                </span>
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                    </section>

                    <section>
                      {/* desktop display 7 limit */}
                      <div className="d-none d-sm-flex align-items-center justify-content-between p-3 pointer">
                        {/* Left side: User profile images */}
                        <Link to={`/leader-board?round=${round.roundId}`}>
                          <div className="d-flex align-items-center">
                            {round.userData
                              .slice(0, maxUserList.desktop)
                              .map((user, idx) =>
                                user.isCreator ? (
                                  <React.Fragment key={idx}>
                                    {user.role === "player" ? (
                                      <img
                                        src={user.profileImage}
                                        alt="player"
                                        width={50}
                                        height={50}
                                        className="rounded-circle player-color-outline me-4"
                                      />
                                    ) : (
                                      <img
                                        src={user.profileImage}
                                        alt="caddie"
                                        width={50}
                                        height={50}
                                        className="rounded-circle caddie-color-outline me-4"
                                      />
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment key={idx}>
                                    {user.role === "player" ? (
                                      <img
                                        src={user.profileImage}
                                        alt="player"
                                        width={50}
                                        height={50}
                                        className="rounded-circle player-color-outline"
                                        style={{
                                          marginLeft: "-10px",
                                          zIndex: maxUserList.desktop - idx,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={user.profileImage}
                                        alt="caddie"
                                        width={50}
                                        height={50}
                                        className="rounded-circle caddie-color-outline"
                                        style={{
                                          marginLeft: "-10px",
                                          zIndex: maxUserList.desktop - idx,
                                        }}
                                      />
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            {round.userData.length > maxUserList.desktop ? (
                              <div
                                className="d-flex justify-content-center align-items-center flex-wrap rounded-circle bg-light theme-light-bg p-1"
                                style={{
                                  width: 50,
                                  height: 50,
                                  opacity: 0.6,
                                }}
                              >
                                <h5 className="text-center">
                                  +{round.userData.length - maxUserList.desktop}{" "}
                                </h5>
                              </div>
                            ) : null}
                          </div>
                        </Link>

                        <Link to={`/review-round?round=${round.roundId}`}>
                          {round.userList
                            ?.filter(
                              (user) =>
                                userData?.uid === user.player?.uid && // Ensure this user has a caddie role
                                (user.isReview === undefined ||
                                  user.isReview === false)
                            )
                            ?.map((user) => {
                              const caddieData = round.userData.find(
                                (data) => data.uid === user.caddie.uid
                              );
                              return (
                                caddieData && (
                                  <div key={caddieData.uid}>
                                    <button className="d-flex align-items-center p-1 lh-20 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                                      <div className="d-flex justify-content-between align-items-center w-100">
                                        <img
                                          src={caddieData.profileImage}
                                          alt="caddie"
                                          width={35}
                                          height={35}
                                          className="rounded-circle caddie-color-outline"
                                          style={{
                                            objectFit: "cover",
                                          }}
                                        />
                                        <ReactSVG
                                          src="assets/images/dooNineIconV3/SVG RoundActivity 030924/RoundActivity-Review.svg"
                                          wrapper="svg"
                                          style={{ height: 35, width: 35 }}
                                        />
                                      </div>
                                    </button>
                                  </div>
                                )
                              );
                            })}
                        </Link>
                      </div>

                      {/* mobile display 5 limit */}
                      <div className="d-sm-none d-flex align-items-center justify-content-between p-3 pointer">
                        {/* Left side: User profile images */}
                        <Link to={`/leader-board?round=${round.roundId}`}>
                          <div className="d-flex align-items-center">
                            {round.userData
                              .slice(0, maxUserList.mobile)
                              .map((user, idx) =>
                                user.isCreator ? (
                                  <React.Fragment key={idx}>
                                    {user.role === "player" ? (
                                      <img
                                        src={user.profileImage}
                                        alt="player"
                                        width={40}
                                        height={40}
                                        className="rounded-circle player-color-outline me-4"
                                      />
                                    ) : (
                                      <img
                                        src={user.profileImage}
                                        alt="caddie"
                                        width={40}
                                        height={40}
                                        className="rounded-circle caddie-color-outline me-4"
                                      />
                                    )}
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment key={idx}>
                                    {user.role === "player" ? (
                                      <img
                                        src={user.profileImage}
                                        alt="player"
                                        width={40}
                                        height={40}
                                        className="rounded-circle player-color-outline"
                                        style={{
                                          marginLeft: "-10px",
                                          zIndex: maxUserList.mobile - idx,
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={user.profileImage}
                                        alt="caddie"
                                        width={40}
                                        height={40}
                                        className="rounded-circle caddie-color-outline"
                                        style={{
                                          marginLeft: "-10px",
                                          zIndex: maxUserList.mobile - idx,
                                        }}
                                      />
                                    )}
                                  </React.Fragment>
                                )
                              )}
                            {round.userData.length > maxUserList.mobile ? (
                              <div
                                className="d-flex justify-content-center align-items-center flex-wrap bg-light theme-light-bg p-1"
                                style={{
                                  width: 40,
                                  height: 40,
                                }}
                              >
                                <h4 className="text-center">
                                  +{round.userData.length - maxUserList.mobile}{" "}
                                </h4>
                              </div>
                            ) : null}
                          </div>
                        </Link>

                        <Link to={`/review-round?round=${round.roundId}`}>
                          {round.userList
                            ?.filter(
                              (user) =>
                                userData?.uid === user.player?.uid && // Ensure this user has a caddie role
                                (user.isReview === undefined ||
                                  user.isReview === false)
                            )
                            ?.map((user) => {
                              const caddieData = round.userData.find(
                                (data) => data.uid === user.caddie.uid
                              );
                              return (
                                caddieData && (
                                  <div key={caddieData.uid}>
                                    <button className="d-flex align-items-center p-1 lh-20 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0">
                                      <div className="d-flex justify-content-between align-items-center w-100">
                                        <img
                                          src={caddieData.profileImage}
                                          alt="caddie"
                                          width={35}
                                          height={35}
                                          className="rounded-circle caddie-color-outline"
                                          style={{
                                            objectFit: "cover",
                                          }}
                                        />
                                        <ReactSVG
                                          src="assets/images/dooNineIconV3/SVG RoundActivity 030924/RoundActivity-Review.svg"
                                          wrapper="svg"
                                          style={{ height: 35, width: 35 }}
                                        />
                                      </div>
                                    </button>
                                  </div>
                                )
                              );
                            })}
                        </Link>
                      </div>
                    </section>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  <h5>No rounds available.</h5>
                </div>
              )}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundFinish;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
