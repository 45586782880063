import { stablefordCalculation } from "../../component/scoreInput/scoreCalculation";

export function mapScores(courseDet, score, userList, userId) {
    // console.log(score);
    
    // Deep copy of courseDet to prevent mutations
    const courseDetailsCopy = JSON.parse(JSON.stringify(courseDet));

    // Create a variable to hold the mapped results
    const mappedScores = courseDetailsCopy.map(hole => {
        // Map over userList to generate scores for each hole
        const scores = userList.map(user => {
            const userIdValue = user.player?.uid || null;
            const caddieIdValue = user.caddie?.uid || null;
            const userScore = score.find(u => u.userId === userIdValue);

            return {
                holeDetail: hole,
                holeScore: userScore ? {
                    ...userScore[`hole${hole.holeNumber}`],
                    docId: userScore.docId
                } : null,

                userId: userIdValue || null,
                caddieId: caddieIdValue || (userScore ? userScore.caddieId : null)
            };
        });

        // Filter out entries where holeScore is null or score is NaN
        const scoresWithUserScores = scores.filter(score => {
            const scoreValue = parseFloat(score.holeScore?.score);
            return score.holeScore !== null && !isNaN(scoreValue);
        });

        // Find the lowest score
        let minScore = Infinity;  // Initialize to positive infinity for comparison

        // First, find the minimum score
        scoresWithUserScores.forEach(score => {
            const scoreValue = parseFloat(score.holeScore.score);
            if (scoreValue < minScore) {
                minScore = scoreValue;
            }
        });

        // Count how many users have the minimum score
        const countMinScoreUsers = scoresWithUserScores.filter(score => parseFloat(score.holeScore.score) === minScore).length;

        // Compare each user score and add a `winner` field in holeScore
        scoresWithUserScores.forEach((scoreA, indexA) => {
            const scoreAValue = parseFloat(scoreA.holeScore.score);

            // Initialize comparison result for each user
            scoreA.holeScore.comparisonResult = [];
            scoreA.holeScore.winner = false; // Default value for winner

            scoresWithUserScores.forEach((scoreB, indexB) => {
                if (indexA === indexB) return;  // Skip self-comparison

                const scoreBValue = parseFloat(scoreB.holeScore.score);

                if (scoreAValue < scoreBValue) {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "lower"
                    });
                } else if (scoreAValue > scoreBValue) {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "higher"
                    });
                } else {
                    scoreA.holeScore.comparisonResult.push({
                        comparedTo: scoreB.userId,
                        result: "equal"
                    });
                }
            });

            // Set the winner field if the score matches the minScore and only one user has it
            if (scoreAValue === minScore && countMinScoreUsers === 1) {
                scoreA.holeScore.winner = true;  // Mark as winner if it matches the min score and is unique
            }
        });


        // Sort the scores array to have the matched userId or caddieId first
        scores.sort((a, b) => {
            const aMatches = a.userId === userId || a.caddieId === userId;
            const bMatches = b.userId === userId || b.caddieId === userId;

            if (aMatches && !bMatches) {
                return -1; // a comes first
            } else if (!aMatches && bMatches) {
                return 1; // b comes first
            }
            return 0; // no change in order
        });

        // Return the hole detail along with the sorted scores
        return {
            holeDetail: hole,
            scores: scores
        };
    });

    return mappedScores;
}

export function sumScores(player) {
    if (!player) return;

    let sumScore = 0;

    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = holeFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

    if (allScoresAreDash) {
        return "-";
    }

    // Sum valid numeric scores
    holeValues.forEach(hole => {
        const holescore = hole?.score;
        if (holescore) {
            const numHolescore = Number(holescore);
            if (!isNaN(numHolescore)) {
                sumScore += numHolescore;
            }
        }
    });

    return sumScore;
}
export function sumScoresToPar(player, courseHoles) {
    if (!player || !courseHoles) return;
    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;


    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

    if (allScoresAreDash) {
        return "-";
    }

    // Calculate the total score relative to par
    holeValues.forEach((holeValue, index) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

        if (holeDetail) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                // Calculate the score relative to par and add it to the sumScore
                sumScore += (score - par);
            }
        }
    });

    return sumScore;
}

export function sumScoreStableford(player, courseHoles) {
    if (!player || !courseHoles) return;
    let sumScore = 0;
    const holeDet = courseHoles.holeDetails;

    const playerHoleFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));
    const holeValues = playerHoleFiltered.map(key => player[key]);

    // Check if all hole scores are "-"
    const allScoresAreDash = holeValues.every(hole => hole?.score === "-");
    if (allScoresAreDash) {
        return "-";
    }

    // Calculate the total Stableford points
    holeValues.forEach((holeValue) => {
        const playerHoleNumber = Number(holeValue?.holeNumber);
        const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

        if (holeDetail) {
            const par = Number(holeDetail?.par);
            const score = Number(holeValue?.score);

            // Only compute if both score and par exist and score is a valid number
            if (!isNaN(score) && !isNaN(par)) {
                // Calculate the score difference
                const scoreDifference = score - par;
                // Get Stableford points based on the score difference
                const stablefordPoints = stablefordCalculation(scoreDifference);
                // Accumulate Stableford points
                sumScore += stablefordPoints;
            }
        }
    });

    return sumScore;
}


export function sumPenalties(player) {
    if (!player) return;

    let sumPenalties = 0

    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = holeFiltered.map(key => player[key]);

    holeValues.forEach(hole => {
        const holePenalties = hole?.penalties;
        if (holePenalties) {
            const numHolePenalties = Number(holePenalties);
            if (!isNaN(numHolePenalties)) {
                sumPenalties += numHolePenalties
            }
        }
    });
    return sumPenalties
}

export function sumPutts(player) {
    if (!player) return;

    let sumPutts = 0

    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = holeFiltered.map(key => player[key]);

    holeValues.forEach(hole => {
        const holePutts = hole?.putts;
        if (holePutts) {
            const numHolePutts = Number(holePutts);
            if (!isNaN(numHolePutts)) {
                sumPutts += numHolePutts
            }
        }
    });
    return sumPutts
}

export function sumPar(courseHole) {
    if (!courseHole) return;

    let sumPar = 0
    const holeDetail = courseHole.holeDetails;

    holeDetail.forEach(hole => {
        const holePar = hole?.par;
        if (holePar) {
            const numHolePar = Number(holePar);
            if (!isNaN(numHolePar)) {
                sumPar += numHolePar
            }
        }
    });
    return sumPar
}

export function checkScore(player) {
    if (!player) return false;

    const holeFiltered = Object.keys(player).filter(key => /hole\d+/.test(key));

    const holeValues = holeFiltered.map(key => player[key]);

    // Check if every hole score is a valid number and not "-"
    const allScoresAreValidNumbers = holeValues.every(hole => {
        const holescore = hole?.score;
        return holescore !== "-" && !isNaN(Number(holescore));
    });

    return allScoresAreValidNumbers;
}





