import React, { useEffect, useState } from "react";

import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import {
  containerStyle,
  searchContainerStyle,
  searchInputStyle,
  chatItemStyle,
  userInfoStyle,
  avatarStyle,
  userTextContainerStyle,
  nameStyle,
  messagePreviewStyle,
  timeStyle,
  notificationBubbleStyle,
} from "./StyleComponent";

import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import axios from "axios";

const menu = "assets/images/message/Chat-Menu.svg";
const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

const ChannelList = ({ channel }) => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isPlayer, setIsPlayer] = useState(false);
  const [friendData, setFriendData] = useState(null);

  const isDirectChannel = channel?.memberCount === 2 && channel?.isDistinct;
  const data = isDirectChannel
    ? channel?.members.find((member) => member.userId !== userData?.dooNineId)
    : null;

  const data2 = !isDirectChannel
    ? channel?.members.find((member) => member.userId == userData?.dooNineId)
    : null;

  // console.log(channel);
  // console.log(data2?.state);

  const lastSeenAt = data ? data.lastSeenAt : null;

  useEffect(() => {
    const fetchData = async () => {
      if (data?.userId) {
        try {
          const q = query(
            collection(firestore, "users"),
            where("dooNineId", "==", data.userId)
          );
          const querySnapshot = await getDocs(q);
          querySnapshot.forEach((doc) => {
            setFriendData(doc.data());
            if (doc.data().role == "player") {
              setIsPlayer(true);
            }
          });
          if (querySnapshot.empty) {
            setIsPlayer(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   async function fetchData() {
  //     const response = await axios.get(
  //       `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channel.url}/metadata`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json; charset=utf8",
  //           "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
  //         },
  //       }
  //     );
  //     console.log(response?.data?.chatType);
  //   }

  //   if (channel) {
  //     fetchData();
  //   }
  // }, [channel]);

  const handleClickChannel = (channel) => {
    navigate("/chat-room?channelId=" + channel);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDeleteChat = (channelUrl) => {
    Swal.fire({
      title: "Do you want to delete this chat?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#ee3d7f",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(
            `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelUrl}`,

            {
              headers: {
                "Content-Type": "application/json; charset=utf8",
                "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
              },
            }
          );

          Swal.fire({
            title: "Delete Channel Success ",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            navigate("/chat");
          });
        } catch (error) {
          console.error("Error leaving channel:", error);
        }
      }
    });
  };

  const handleProfileClick = (uid) => {
    if (!uid) return;

    if (uid === userData.uid) {
      navigate("/userPage");
    } else {
      navigate("/user", { state: { uid: uid } });
    }
  };

  const handleLeaveChat = async (channelUrl) => {
    Swal.fire({
      title: "Do you want to leave this chat?",
      icon: "warning",
      confirmButtonColor: "#ee3d7f",
      confirmButtonText: "OK",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response2 = await axios.post(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelUrl}/messages`,
          {
            message_type: "MESG",
            user_id: userData.dooNineId,
            message: `${userData.firstName} ${userData.lastName} leave this group chat.`,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );

        const response = await axios.put(
          "https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/" +
            channelUrl +
            "/leave",
          {
            user_ids: [userData.dooNineId],
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": "1a3a0f2103a01d56c01cde731a802118945aeeb4",
            },
          }
        );
        if (response.status == 200) {
          Swal.fire({
            icon: "success",
            title: "Leave group success",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          }).then(async (result) => {
            // navigate("/chat");
          });
        } else {
          console.log(response);
          Swal.fire({
            icon: "error",
            title: "Leave group failed",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  return (
    <>
      {isDirectChannel || data2?.state == "joined" ? (
        <div
          key={channel.url}
          style={{ ...chatItemStyle, cursor: "pointer" }}
          onClick={() => handleClickChannel(channel.url)}
        >
          <div style={userInfoStyle}>
            <div style={{ position: "relative" }}>
              {isDirectChannel ? (
                <img
                  src={data?.plainProfileUrl}
                  alt="avatar"
                  style={{
                    ...avatarStyle,
                    outline: isPlayer
                      ? "3px solid #4a74e5"
                      : "3px solid #ee3d7f",
                  }}
                />
              ) : (
                <img
                  src={channel?.coverUrl}
                  alt="chat cover"
                  style={{
                    ...avatarStyle,
                    outline: "3px solid #81d77a",
                  }}
                />
                // <div
                //   style={{
                //     ...avatarStyle,
                //     display: "flex",
                //     justifyContent: "center",
                //     alignItems: "center",
                //     backgroundColor: "white",
                //     fontSize: "18px",
                //     color: "#ee3d7f",
                //     outline: "3px solid #81d77a",
                //   }}
                // >
                //   {channel.memberCount}
                // </div>
              )}
              {isDirectChannel && (
                <div
                  style={{
                    position: "absolute",
                    bottom: "0px", // ปรับตำแหน่งตามต้องการ
                    right: "10px", // ปรับตำแหน่งตามต้องการ
                    width: "16px",
                    height: "16px",
                    backgroundColor: lastSeenAt == "0" ? "#3ce44d" : "#B0B5BD",
                    borderRadius: "50%",
                    border: "2px solid white",
                    zIndex: 2, // ตรวจสอบค่า z-index
                  }}
                ></div>
              )}
            </div>
            <div style={userTextContainerStyle}>
              {/* <h5 className="fw-600 mb-1" >
                {isDirectChannel
                  ? data?.nickname
                  : channel?.name?.length > 15
                  ? `${channel?.name?.substring(0, 15)}...`
                  : channel?.name}
              </h5> */}

              <h5
                className="fw-600 mb-1"
                style={{
                  width: "35vw",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {isDirectChannel
                  ? data?.nickname
                  : `${channel?.name} (${channel?.memberCount})`}
              </h5>

              <span
                style={{
                  ...messagePreviewStyle,
                  width: "35vw",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {channel.lastMessage?.message}
              </span>
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span style={timeStyle}>
              {formatDate(channel.lastMessage?.createdAt)}
            </span>
            <span style={notificationBubbleStyle(channel.unreadMessageCount)}>
              {channel.unreadMessageCount}
            </span>

            <div onClick={(e) => e.stopPropagation()}>
              <ReactSVG
                src={menu}
                style={{ width: "35px", cursor: "pointer" }}
                onClick={toggleDropdown}
              />

              {dropdownOpen && (
                <Dropdown
                  className="drodownFriend"
                  drop="down-centered"
                  show={dropdownOpen}
                  onToggle={toggleDropdown}
                >
                  <Dropdown.Toggle
                    as="i"
                    id="dropdown-basic"
                    style={{
                      cursor: "pointer",
                      zIndex: 1000,
                    }}
                  ></Dropdown.Toggle>

                  <Dropdown.Menu
                    className="dropdownChat"
                    as={CustomDropdown}
                    popperConfig={{
                      modifiers: [
                        {
                          name: "flip",
                          options: {
                            altBoundary: true,
                            rootBoundary: "viewport",
                            padding: 8,
                          },
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            altAxis: true,
                            altBoundary: true,
                            tether: false,
                          },
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ],
                    }}
                    style={{ zIndex: 1050 }}
                  >
                    {isDirectChannel && (
                      <Dropdown.Item
                        style={{ display: "flex" }}
                        onClick={() => handleProfileClick(friendData?.uid)}
                      >
                        Profile
                      </Dropdown.Item>
                    )}
                    <Dropdown.Item
                      style={{ display: "flex" }}
                      onClick={() => handleLeaveChat(channel.url)}
                    >
                      Leave Chat
                    </Dropdown.Item>
                    {/* <Dropdown.Item
                  style={{ display: "flex" }}
                  onClick={() => handleDeleteChat(channel.url)}
                >
                  Delete Chat
                </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const formatDate = (createdAt) => {
  if (createdAt == undefined) {
    return " ";
  }

  const date = new Date(createdAt);
  const now = new Date();

  // คำนวณความแตกต่างของเวลาในวัน
  const timeDifference = now - date;
  const oneDay = 24 * 60 * 60 * 1000; // หนึ่งวันในมิลลิวินาที

  if (timeDifference < oneDay && date.getDate() === now.getDate()) {
    // ถ้าเป็นวันนี้ แสดงเวลา
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${period}`;
  } else if (
    timeDifference < 2 * oneDay &&
    now.getDate() - date.getDate() === 1
  ) {
    // ถ้าเป็นเมื่อวาน แสดง "Yesterday"
    return "Yesterday";
  } else {
    // แสดงวันที่ในรูปแบบเดือน/วัน
    const options = { month: "short", day: "numeric" }; // เดือนแบบสั้น เช่น "Aug"
    return date.toLocaleDateString("en-US", options);
  }
};

export default ChannelList;

function CustomDropdown({ children, ...props }) {
  return ReactDOM.createPortal(
    <Dropdown.Menu {...props}>{children}</Dropdown.Menu>,
    document.body
  );
}
