import { sumPenalties, sumPutts } from "../../scoreV2/scoring/module/scoreCalculation";
import { stablefordCalculation } from "../../scoreV2/component/scoreInput/scoreCalculation";

export function gameModeSelection(scoreList, roundData) {
    switch (roundData.gameMode) {
        case 'Stroke Play':
            return strokePlaySelected(scoreList, roundData)

        case 'Stableford':
            return stablefordSelected(scoreList, roundData)

        case 'Match Play':
            return matchPlaySelected(scoreList, roundData)

        default:
            console.warn('Incrroect game mode used "Stroke Play"');
            return strokePlaySelected(scoreList, roundData)
    }
}

function strokePlaySelected(scoreList, roundData) {
    switch (roundData.scoring) {
        case 'Gross':
            return grossCalculation(scoreList, roundData);

        case 'To Par':
            return toparCalculation(scoreList, roundData);

        default:
            console.warn('Incrroect scoring used "Gross"');
            return grossCalculation(scoreList, roundData);
    }
}

function stablefordSelected(scoreList, roundData) {
    switch (roundData.scoring) {
        case 'Gross':
            return grossStbCalculation(scoreList, roundData);

        default:
            console.warn('Incrroect scoring used "Gross"');
            return grossStbCalculation(scoreList, roundData)
    }
}

function matchPlaySelected(scoreList, roundData) {
    switch (roundData.scoring) {
        case 'Gross':
            return grossCalculation(scoreList, roundData)
        case 'To Par':
            return toparCalculation(scoreList, roundData)

        default:
            console.warn('Incrroect scoring used "Gross"');
            return grossCalculation(scoreList, roundData)
    }
}

function grossCalculation(scoreList, roundData) {

    switch (roundData.handicap) {
        case 'world_handicap_system':
        case 'whs':
        case 's36':
        case 'scratch':
            return grossScratch(scoreList)

        // case 'world_handicap_system':
        // case 'whs':
        //     return grossWhs(scoreList)

        // case 's36':
        //     return grossS36(scoreList)

        default:
            console.warn('Incorrect handicap used "Scratch"');
            return grossScratch(scoreList)
    }
}

function grossStbCalculation(scoreList, roundData) {

    switch (roundData.handicap) {
        case 'world_handicap_system':
        case 'whs':
        case 's36':
        case 'scratch':
            return grossStbScratch(scoreList, roundData)

        // case 'world_handicap_system':
        // case 'whs':
        //     return grossStbWhs(scoreList)

        // case 's36':
        //     return grossStbS36(scoreList)

        default:
            console.warn('Incorrect handicap used "Scratch"');
            return grossScratch(scoreList)
    }
}

function toparCalculation(scoreList, roundData) {
    switch (roundData.handicap) {
        case 'world_handicap_system':
        case 'whs':
        case 's36':
        case 'scratch':
            return toparScratch(scoreList, roundData);

        // case 'world_handicap_system':
        // case 'whs':
        //     return toparWhs(scoreList, roundData);

        // case 's36':
        //     return toparS36(scoreList, roundData);

        default:
            console.warn('Incorrect handicap used "Scratch"');
            return toparScratch(scoreList, roundData);
    }
}


// gross ===========================================================================
function grossScratch(scoreList) {
    // Process each score to calculate sumScore
    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        // Filter keys matching "hole" pattern (e.g., hole1, hole2, ...)
        const holeFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));

        // Extract the hole values
        const holeValues = holeFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

        // If all scores are "-", set sumScore to "-"
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // Calculate the sum of valid numeric scores
        holeValues.forEach(hole => {
            const holescore = hole?.score;
            if (holescore) {
                const numHolescore = Number(holescore);
                if (!isNaN(numHolescore)) {
                    sumScore += numHolescore;
                }
            }
        });
        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        // Return the updated score object
        return { ...score, sumScore, sumPutt, sumPenal };
    });

    return sortScoreList(updatedScoreList);
}

function grossWhs(scoreList) {

}
function grossS36(scoreList) {

}

// to par =========================================================================
function toparScratch(scoreList, roundData) {
    if (!scoreList || !roundData.courseHoleData) return;

    const holeDet = roundData.courseHoleData.holeDetails;

    // Process each score in the scoreList
    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        // Filter keys to find holes
        const playerHoleFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));

        // Extract hole values
        const holeValues = playerHoleFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");

        if (allScoresAreDash) {
            // If all scores are "-", set toPar as "-" and return updated object
            return { ...score, sumScore: '-' };
        }

        // Calculate total score relative to par
        holeValues.forEach(holeValue => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const par = Number(holeDetail?.par);
                const score = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(score) && !isNaN(par)) {
                    // Calculate the score relative to par and add to sumScore
                    sumScore += (score - par);
                }
            }
        });

        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        // Return the updated score object
        return { ...score, sumScore, sumPutt, sumPenal };
    });


    return sortScoreList(updatedScoreList);
}

function toparWhs(scoreList, roundData) {

}
function toparS36(scoreList, roundData) {

}

// stableford ===============================================================
function grossStbScratch(scoreList, roundData) {

    const holeDet = roundData.courseHoleData.holeDetails;

    const updatedScoreList = scoreList.map(score => {
        let sumScore = 0;

        const playerHoleFiltered = Object.keys(score).filter(key => /hole\d+/.test(key));
        const holeValues = playerHoleFiltered.map(key => score[key]);

        // Check if all hole scores are "-"
        const allScoresAreDash = holeValues.every(hole => hole?.score === "-");
        if (allScoresAreDash) {
            return { ...score, sumScore: '-' };
        }

        // Calculate the total Stableford points
        holeValues.forEach((holeValue) => {
            const playerHoleNumber = Number(holeValue?.holeNumber);
            const holeDetail = holeDet.find(hole => hole.holeNumber === playerHoleNumber);

            if (holeDetail) {
                const par = Number(holeDetail?.par);
                const score = Number(holeValue?.score);

                // Only compute if both score and par exist and score is a valid number
                if (!isNaN(score) && !isNaN(par)) {
                    // Calculate the score difference
                    const scoreDifference = score - par;
                    // Get Stableford points based on the score difference
                    const stablefordPoints = stablefordCalculation(scoreDifference);
                    // Accumulate Stableford points
                    sumScore += stablefordPoints;
                }
            }
        });
        const sumPutt = sumPutts(score);
        const sumPenal = sumPenalties(score)

        return { ...score, sumScore, sumPutt, sumPenal };
    })
    return sortScoreList(updatedScoreList)
}
function grossStbWhs(params) {

}
function grossStbS36(params) {

}
// stableford ===============================================================

function sortScoreList(scoreList) {
    return scoreList.sort((a, b) => {
        const scoreA = a.sumScore;
        const scoreB = b.sumScore;

        // Place "-" (non-numeric) at the end
        if (scoreA === "-") return 1;
        if (scoreB === "-") return -1;

        // Compare numeric scores
        return scoreA - scoreB;
    });
}

