import { Card } from "react-bootstrap";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState, useRef } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import FriendRequest from "./FriendRequest";
import ConfirmedRound from "./ConfirmedRound";
import AllRound from "./AllRound";

import { useLocation, useNavigate } from "react-router-dom";

function RoundUpcoming() {
  const [listRound, setListRound] = useState([]);
  const [listRoundStart, setListRoundStart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("confirmedRounds");
  const userData = useSelector((state) => state.user.data);
  const maxUserList = { desktop: 7, mobile: 5 };
  const location = useLocation();
  const navigate = useNavigate();
  const friendRequestRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roundDataArray = [];

        const fetchScoreDetails = async (roundId, playerUids) => {
          if (playerUids.length === 0) return [];
          
          const userQuery = query(
            collection(firestore, "scoreCards"),
            where("userId", "in", playerUids),
            where("roundId", "==", roundId)
          );
          const caddieQuery = query(
            collection(firestore, "scoreCards"),
            where("caddieId", "in", playerUids),
            where("roundId", "==", roundId)
          );
        
          const [userSnapshot, caddieSnapshot] = await Promise.all([
            getDocs(userQuery),
            getDocs(caddieQuery),
          ]);
        
          let scoreDetailsArray = [];
        
          userSnapshot.forEach((doc) => {
            scoreDetailsArray.push(doc.data());
          });
        
          caddieSnapshot.forEach((doc) => {
            scoreDetailsArray.push(doc.data());
          });
        
          return scoreDetailsArray;
        };        

        const userQuery = query(
          collection(firestore, "round"),
          where("teeTime", "==", "now")
        );
        const userSnapshot = await getDocs(userQuery);

        const roundPromises = userSnapshot.docs.map(async (roundDoc) => {
          const roundData = roundDoc.data();
          const isCreator = roundData.creator === userData?.uid;
          const isUserInList = roundData.userList.some(
            (user) =>
              (user.player?.uid === userData?.uid &&
                user.player?.isJoin === true) ||
              (user.caddie?.uid === userData?.uid &&
                user.caddie?.isJoin === true)
          );
          if (isCreator || isUserInList) {
            const playerUids = roundData.userList
              .filter(
                (user) =>
                  user.player?.uid === userData.uid ||
                  user.caddie?.uid === userData.uid // Check for matching uids in both player and caddie
              )
              .map((user) => user.player?.uid || user.caddie?.uid);
            if (playerUids.length > 0) {
              const scoreDetailsArray = await fetchScoreDetails(
                roundData.roundId,
                playerUids
              );

              roundData.userList = roundData.userList.map((user) => {
                const playerScore = scoreDetailsArray.find(
                  (details) =>
                    details.userId === user.player?.uid ||  details.caddieId === user.caddie?.uid
                );
                const status = playerScore ? playerScore.status : undefined;
                return {
                  ...user,
                  player: { ...user.player, status },
                  caddie: { ...user.caddie, status },
                };
              });

              const isRoundStatus = roundData.userList.some(
                (user) =>
                  (user.player?.uid === userData.uid && user.player !== null && (user.player?.status === "playing" || user.player?.status == undefined)) ||
                  (user.caddie?.uid === userData.uid && user.caddie !== null && (user.caddie?.status === "playing" || user.caddie?.status == undefined))
              );

              if (isRoundStatus && roundData.status !== "deleted") {
                roundDataArray.push({
                  ...roundData,
                  roundId: roundDoc.id,
                });
              }
            }
          }
        });

        await Promise.all(roundPromises); // Run all round processing in parallel

        setListRoundStart(roundDataArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userData]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, "round")),
      async (roundSnapshot) => {
        try {
          setLoading(true);
          let roundDataArray = [];
          let fetchedCourses = {};
          let fetchedUsers = {};

          roundSnapshot.forEach((doc) => {
            const roundData = doc.data();

            const isCreator = roundData.creator === userData?.uid;
            const isUserInList = Array.isArray(roundData.userList) && roundData.userList.some(
              (user) =>
                (user.player?.uid === userData?.uid &&
                  user.player?.isJoin === true) ||
                (user.caddie?.uid === userData?.uid &&
                  user.caddie?.isJoin === true)
            );

            if (isCreator || isUserInList) {
              if (
                roundData.teeTime === "schedule" &&
                (roundData.status == undefined ||
                  roundData.status !== "deleted")
              ) {
                roundDataArray.push({ ...roundData, roundId: doc.id });
              }
            }
          });

          const fetchUserDetails = async (uid) => {
            if (fetchedUsers[uid]) return fetchedUsers[uid]; // Use cached result

            const q = query(
              collection(firestore, "users"),
              where("uid", "==", uid)
            );
            const userSnapshot = await getDocs(q);
            let userDetails = {};
            userSnapshot.forEach((userDoc) => {
              userDetails = userDoc.data();
            });

            fetchedUsers[uid] = userDetails; // Cache the result
            return userDetails;
          };

          const fetchCourseDetails = async (courseId) => {
            if (fetchedCourses[courseId]) return fetchedCourses[courseId]; // Use cached result

            const courseQuery = query(
              collection(firestore, "courses"),
              where("id", "==", courseId)
            );
            const courseSnapshot = await getDocs(courseQuery);
            let courseData = {};
            courseSnapshot.forEach((doc) => {
              courseData = doc.data();
            });

            fetchedCourses[courseId] = courseData; // Cache the result
            return courseData;
          };

          const promises = roundDataArray.map(async (round) => {
            let holesCourse = "";

            if (round.holesCourse) {
              const courseHolesSnapshot = await getDoc(
                doc(firestore, "courseHoles", round.holesCourse)
              );
              if (courseHolesSnapshot.exists) {
                const courseHoleName = courseHolesSnapshot.data().courseHoleName;
                holesCourse = courseHoleName || "";
              }
            } else {
              console.warn("round.holesCourse is empty or undefined.");
              holesCourse = "";
            }

            const { time, day, month } = epochToDateTime(round.scheduleTime);
            let userDataArray = [];

            for (let userObj of round.userList) {
              const { caddie, player } = userObj;

              if (player?.uid) {
                const playerData = await fetchUserDetails(player.uid);
                userDataArray.push({
                  ...playerData,
                  isCreator: player.isCreator,
                  isJoin: player.isJoin,
                });
              }

              if (caddie?.uid) {
                const caddieData = await fetchUserDetails(caddie.uid);
                userDataArray.push({
                  ...caddieData,
                  isCreator: caddie.isCreator,
                  isJoin: caddie.isJoin,
                });
              }
            }

            const courseData = await fetchCourseDetails(round.course);

            return {
              ...round,
              courseInfo: courseData,
              time,
              day,
              month,
              userData: userDataArray,
              holesCourse,
            };
          });

          const results = await Promise.all(promises);

          setListRound(results);
        } catch (error) {
          console.error("Error fetching data: ", error);
        } finally {
          setLoading(false); // Set loading to false after data is fetched
        }
      }
    );

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, [userData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedOptionFromQuery = searchParams.get("selectedOption");

    if (selectedOptionFromQuery === "friendRequest") {
      setSelectedOption("friendRequest");

      navigate("/round-activity", { replace: true }); // เคลียร์ query parameter ออกจาก URL

      setTimeout(() => {
        friendRequestRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }
  }, [location.search, navigate]);

  const handleSelectionChange = (e) => {
    setSelectedOption(e.target.value);
  };
// console.log(listRoundStart)
  return (
    <>
      <Card className="border-0 shadow-xss rounded-xxl">
        <Card.Body className="main-player-stat">
          {loading ? (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
              <div className="d-flex align-items-center mb-2">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  style={{
                    height: "40px",
                  }}
                  value={selectedOption}
                  onChange={handleSelectionChange}
                >
                  <option selected value="confirmedRounds">
                    Confirmed Round
                  </option>
                  <option value="friendRequest">Friend Request</option>
                  <option value="allRounds">All Rounds</option>
                </select>

                {/* Icon for filtering */}
                <div
                  style={{
                    cursor: "pointer",
                    height: "40px",
                    width: "40px",
                  }}
                  className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
                >
                  <i className="feather-filter font-xss text-grey-500"></i>
                </div>
              </div>

              <div ref={friendRequestRef}>
                {" "}
                {/* ตำแหน่งที่จะ scroll ลงไป */}
                {selectedOption === "confirmedRounds" &&
                listRound.length > 0 ? (
                  <ConfirmedRound
                    listRound={listRound}
                    userData={userData}
                    maxUserList={maxUserList}
                    listRoundStart={listRoundStart}
                    loading={loading}
                  />
                ) : selectedOption === "friendRequest" ? (
                  <FriendRequest />
                ) : selectedOption === "allRounds" ? (
                  <AllRound />

                ) : (
                  <h5 className="text-center p-2">No rounds available.</h5>
                )}
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
export default RoundUpcoming;
