import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";

import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs, or, and } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { ReactSVG } from "react-svg";

import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";

import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { ToggleButton } from "react-bootstrap";
import clsx from "clsx";
import { Link } from "react-router-dom";

import CreatePrivateChat from "./component/CreatePrivateChat";
import CreateGroupChatBody from "./component/CreateGroupChatBody";

function CreateGroupChat() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);

  const [gameModeVal, setGameModeVal] = useState("private_chat");

  const gameModeButton = [
    {
      name: "Private Chat",
      value: "private_chat",
    },
    {
      name: "Group Chat",
      value: "group_chat",
    },
  ];

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />
      <SendbirdProvider
        appId={"B72515CE-E0DA-417F-AF02-4BF3D07B3D50"}
        userId={userData?.dooNineId}
        accessToken={userData?.sendBirdAccessToken}
      >
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 pageMyFriend">
                  <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                    <div className="d-flex w-100 align-items-center">
                      <Link to="/chat" className="d-inline-block me-3">
                        <i className="ti-arrow-left font-sm text-black"></i>
                      </Link>
                      <h2 className="fw-700 mb-0 font-md text-grey-900">
                        Create chat
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="card d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                  <div className="card-body p-3">
                    <div className="mb-3">
                      <h5 className="mt-3 mb-2 fw-600">Chat Mode</h5>
                      <div className="d-flex align-items-center gap-2 mb-2 flex-wrap">
                        {gameModeButton.map((radio, idx) => (
                          <div key={idx} className="radio-container col-sm-3">
                            <ToggleButton
                              id={`mode-${idx}`}
                              type="radio"
                              className={clsx("inactive w-100 theme-dark-bg", {
                                active: gameModeVal === radio.value,
                              })}
                              variant="light"
                              name="mode"
                              value={radio.value}
                              checked={gameModeVal === radio.value}
                              onChange={(e) => setGameModeVal(e.target.value)}
                            >
                              <h6 className="fw-600">{radio.name}</h6>
                            </ToggleButton>
                            <i
                              className={clsx(
                                "bi bi-check-circle-fill text-current checked-icon",
                                { "d-none": gameModeVal !== radio.value }
                              )}
                            ></i>
                          </div>
                        ))}
                      </div>
                    </div>

                    {gameModeVal === "private_chat" ? (
                      <CreatePrivateChat />
                    ) : (
                      <CreateGroupChatBody />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SendbirdProvider>
      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default CreateGroupChat;
