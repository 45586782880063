import { doc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { firestore } from '../../../components/firebase/FirebaseConfig';
import Swal from 'sweetalert2';
import LoadCircle from '../../../components/LoadCircle';

function CreateLink({ roundData }) {
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [created, setCreated] = useState(false);

    useEffect(() => {
        if (roundData?.isLeaderBoardPublic) {
            setCreated(true)
        }
    }, [roundData])


    const handleCreateLink = async () => {
        Swal.fire({
            title: "Leaderboard Not Public",
            html: `
            <h5 class="fw-600">You need to set the leaderboard to public.</h5>
            <h5 class="fw-600">This will allow anyone with the link to view it.</h5>
          `,
            icon: "warning",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
            showCancelButton: true,
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const ref = doc(firestore, "round", roundData.roundId);
                await updateDoc(ref, {
                    isLeaderBoardPublic: true,
                });
                //     Swal.fire({
                //         title: "Leaderboard Public",
                //         html: `
                //     <h5 class="fw-600">The leaderboard is now public.</h5>
                //     <h5 class="fw-600">You can share the link with anyone.</h5>
                //   `,
                //         icon: "success",
                //         confirmButtonColor: "#ee3d7f",
                //         confirmButtonText: "OK",
                //     });
                setCreated(true);
                setLoading(false);
            }
        }).catch((error) => {
            console.error('Fail to create link', error);
            setLoading(false);
        });
    };

    const handleCopyToClipboard = () => {
        const url = `https://www.doonine.com/leader-board?round=${roundData.roundId}`;
        navigator.clipboard
            .writeText(url)
            .then(() => {
                setCopied(true);

                setTimeout(() => {
                    setCopied(false);
                }, 1000);
            })
            .catch((err) => {
                console.error("Failed to copy: ", err);
            });
    };

    const handleDelete = () => {
        Swal.fire({
            title: "Change to Private",
            html: `
            <h5 class="fw-600">You need to set the leaderboard to private.</h5>
            <h5 class="fw-600">Only player & caddies in round will be able to view it.</h5>
          `,
            icon: "warning",
            confirmButtonColor: "#ee3d7f",
            confirmButtonText: "OK",
            showCancelButton: true,
            cancelButtonText: "Cancel",
        }).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true);
                const ref = doc(firestore, "round", roundData.roundId);
                await updateDoc(ref, {
                    isLeaderBoardPublic: false,
                });
                //     Swal.fire({
                //         title: "Leaderboard Set to Private",
                //         html: `
                //     <h5 class="fw-600">The leaderboard is now private.</h5>
                //     <h5 class="fw-600">Only player & caddies in round will be able to view it.</h5>
                //   `,
                //         icon: "success",
                //         confirmButtonColor: "#ee3d7f",
                //         confirmButtonText: "OK",
                //     });
                setCreated(false);
                setLoading(false);
            }
        });
    };

    // console.log(roundData);

    return (
        <div className='mt-3'>
            <h2 className='fw-600 mb-2'>Public Link</h2>
            {!created ?
                <>
                    {!loading ?
                        <button className='btn bg-light theme-dark-bg' onClick={() => handleCreateLink()}>
                            <h4 className='fw-600'>Create</h4>
                        </button>
                        :
                        <LoadCircle variant='dark' />
                    }
                </>
                :
                <>
                    <input type="text" name="leaderboard-link" id="leaderboard-link" disabled
                        className='form-control form-control-sm'
                        value={`https://www.doonine.com/leader-board?round=${roundData.roundId}`}
                    />
                    <div className='d-flex justify-content-end align-items-center gap-2 mt-2'>
                        {!loading ?
                            <button className='btn bg-light theme-dark-bg' onClick={() => handleDelete()}>
                                <h4 className='fw-600'>Delete</h4>
                            </button>
                            :
                            <LoadCircle variant='dark' />
                        }
                        <button className='btn bg-light theme-dark-bg' onClick={() => handleCopyToClipboard()}>
                            {!copied ?
                                <h4 className='fw-600'>Copy</h4>
                                :
                                <div className='d-flex justify-content-center align-items-center'>
                                    <i className='bi-check-lg text-success me-1'></i>
                                    <h4 className='fw-600'>Copied</h4>
                                </div>
                            }
                        </button>
                    </div>
                </>
            }
        </div>
    )
}

export default CreateLink