


export const containerStyle = {
    width: "100%",
    padding: "15px 5px 15px 5px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
};

export const searchContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 0",
};

export const searchInputStyle = {
    width: "100%",
    padding: "10px",
    borderRadius: "20px",
    border: "1px solid #ccc",
    outline: "none",
};

export const chatItemStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px",
    borderBottom: "1px solid #f0f0f0",
};

export const userInfoStyle = {
    display: "flex",
    alignItems: "center",
};

export const avatarStyle = {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    marginRight: "15px",
};

export const userTextContainerStyle = {
    display: "flex",
    flexDirection: "column",
};

export const nameStyle = {
    // fontWeight: "bold",
    // fontSize: "14px",
    // marginBottom: "5px",
};

export const messagePreviewStyle = {
    color: "#aaa",
    fontSize: "12px",
};

export const timeStyle = {
    color: "#aaa",
    fontSize: "12px",
};

export const notificationBubbleStyle = (count) => ({
    backgroundColor: "#f06292",
    color: "#fff",
    borderRadius: "50%",
    minWidth: "1.75rem", // กำหนดขนาดขั้นต่ำเท่ากับความสูง
    height: "1.75rem",
    display: count ? "inline-flex" : "none", // ใช้ "inline-flex" เพื่อให้สามารถจัดตำแหน่งข้อความได้
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "10px",
    padding: "0 6px", // เพิ่ม padding เพื่อรองรับข้อความยาว
    textAlign: "center", // จัดข้อความให้อยู่ตรงกลาง
});
