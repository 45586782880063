import { addDoc, collection, getDocs, query, updateDoc, where, orderBy, limit } from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";

export async function getCompletedRound(uid, numberOfRound) {
    if (!uid) {
        console.error('incorrect uid', uid);
        return;
    }
    try {
        const scoreCollection = collection(firestore, 'scoreCards');
        let q = query(
            scoreCollection,
            where('userId', '==', uid),
            where('status', '==', 'finished'),
            orderBy('created', 'desc') // Sort by "created" in descending order
        );
        if (numberOfRound > 0) {
            q = query(q, limit(numberOfRound));
        }

        const scoreSnapshot = await getDocs(q);
        const scoreList = []
        if (!scoreSnapshot.empty) {
            scoreSnapshot.forEach(doc => {
                const data = doc.data();
                if (data.status === 'finished') {
                    scoreList.push(data);
                }
            });
            return scoreList
        }
    } catch (error) {
        console.error('Fail to get completed round', error);
    }
}

export function getPuttPerHole(scoreList) {
    if (!scoreList) {
        return "-"
    }
    // console.log(scoreList);
    // Array to hold the extracted values for each hole
    const puttsPerHole = [];

    // Loop through each score object in the scoreList array
    scoreList.forEach(score => {
        // Extract each "hole" field in the format "hole" followed by a number
        Object.keys(score).forEach(key => {
            // Check if the key matches the pattern "hole" followed by a number
            if (/^hole\d+$/.test(key)) {
                const scoreKey = score[key];
                if (scoreKey.putts && scoreKey.putts === "-") {
                    scoreKey.putts = 0
                    puttsPerHole.push(scoreKey.putts);
                } else {
                    const puttNum = Number(scoreKey.putts);
                    puttsPerHole.push(puttNum);
                }
            }
        });
    });
    const totalPutts = puttsPerHole.reduce((sum, putt) => sum + putt, 0);

    const avgPuttPerHole = puttsPerHole.length > 0 ? totalPutts / puttsPerHole.length : 0;

    // Print and return the array of putts per hole
    // console.log("Extracted putts per hole:", avgPuttPerHole);
    return avgPuttPerHole.toFixed(2)
}

export function getGir(scoreList) {
    if (!scoreList) {
        return "-"
    }
    // console.log(scoreList);

    let girCount = 0; // Count of holes where GIR (center fairway hit) is achieved
    let totalHoles = 0; // Total number of holes

    // Loop through each score object in the scoreList array
    scoreList.forEach(score => {
        // Loop through each "hole" field in the score object
        Object.keys(score).forEach(key => {
            // Check if the key matches the pattern "hole" followed by a number
            if (/^hole\d+$/.test(key)) {
                const scoreKey = score[key];
                totalHoles++; // Increment total holes

                // Check for GIR based on fairway hit being "center"
                if (scoreKey.fairwayHit === "center") {
                    girCount++; // Increment GIR count if fairway hit is "center"
                }
            }
        });
    });

    // Calculate GIR percentage
    const girPercentage = totalHoles > 0 ? (girCount / totalHoles) * 100 : 0;

    // Print and return the GIR percentage
    // console.log("GIR Percentage:", girPercentage.toFixed(2) + "%");
    return girPercentage.toFixed(2); // Return as a string with 2 decimal places
}

export async function bestScoreCalculation(playerScore, courseHoles) {
    const holeList = courseHoles.holeDetails;
    let totalScore = 0;
    let totalPar = 0;
    let bestHole = null;
    let bestHoleScore = Infinity;  // Start with Infinity to find the minimum score
    let bestScoreToPar = Infinity

    holeList.forEach(hole => {
        try {
            // Extract and validate hole score and par values
            const holeScore = Number(playerScore[`hole${hole.holeNumber}`]?.score);
            const par = Number(hole.par);

            // Error handling for invalid data
            if (isNaN(holeScore) || isNaN(par)) {
                console.log(`Hole ${hole.holeNumber}: Invalid data for score or par`);
                return; // Skip this hole if data is invalid
            }

            // Accumulate total scores and par values for overall comparison
            totalScore += holeScore;
            totalPar += par;

            // Track the lowest score across all holes (gross)
            // if (holeScore < bestHoleScore) {
            //     bestHoleScore = holeScore;
            //     bestHole = hole.holeNumber;
            // }

            // Track the lowest score across all holes (to par)
            bestHoleScore = holeScore - par;
            if (bestHoleScore < bestScoreToPar) {
                bestScoreToPar = bestHoleScore;
                bestHole = hole.holeNumber

            }

        } catch (error) {
            console.error(`Error processing hole ${hole.holeNumber}:`, error.message);
        }
    });

    // Calculate and display the player's total score relative to par
    // const totalScoreToPar = totalScore - totalPar;
    // if (totalScoreToPar < 0) {
    //     console.log(`Total Score: ${totalScore} (${totalScoreToPar} relative to Par)`);
    // } else if (totalScoreToPar > 0) {
    //     console.log(`Total Score: ${totalScore} (+${totalScoreToPar} relative to Par)`);
    // } else {
    //     console.log(`Total Score: ${totalScore} (Even Par)`);
    // }

    // Display the best individual hole score, regardless of par

    if (bestHole !== null) {

        try {
            const playerStatCollection = collection(firestore, 'playerStatistic');
            const q = query(playerStatCollection, where('userId', '==', playerScore.userId));
            const statSnapshot = await getDocs(q);
            if (!statSnapshot.empty) {
                const statDoc = statSnapshot.docs[0]
                const statRef = statDoc.ref;
                const statData = statDoc.data()

                if (statData.bestScore === undefined || typeof statData.bestScore !== 'object' || statData?.bestScore?.bestHoleScore >= bestScoreToPar) {
                    await updateDoc(statRef, {
                        bestScore: {
                            bestHoleScore: bestScoreToPar,
                            roundId: playerScore.roundId
                        }
                    })
                }
            } else {
                await addDoc(playerStatCollection, {
                    bestScore: bestScoreToPar,
                    userId: playerScore.userId
                })
            }
        } catch (error) {
            console.error('Fail to update best score', error);
        }
    } else {
        console.warn("No valid hole scores were found.");
        console.warn('hole:', bestHole);
        console.warn('score:', bestScoreToPar);
    }
}

export async function getBestScore(uid) {
    try {
        const playerStatCollection = collection(firestore, 'playerStatistic');
        const q = query(playerStatCollection, where('userId', '==', uid));
        const statSnapshot = await getDocs(q);
        if (!statSnapshot.empty) {
            const statData = statSnapshot.docs[0].data();
            if (statData.bestScore !== undefined) {
                return statData.bestScore;
            } else {
                return "-"
            }
        } else {
            return "-"
        }
    } catch (error) {
        console.error('Fail to get best score', error);
    }
}










