import React, { Fragment, useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";

import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import { ReactSVG } from "react-svg";

import Swal from "sweetalert2";

import SendbirdChat from "@sendbird/chat";
import { useNavigate } from "react-router-dom";

import {
  GroupChannelModule,
  GroupChannelFilter,
  GroupChannelListOrder,
  MessageFilter,
  MessageCollectionInitPolicy,
} from "@sendbird/chat/groupChannel";

import {
  containerStyle,
  searchContainerStyle,
  searchInputStyle,
  chatItemStyle,
  userInfoStyle,
  avatarStyle,
  userTextContainerStyle,
  nameStyle,
  messagePreviewStyle,
  timeStyle,
  notificationBubbleStyle,
} from "./component/StyleComponent";

import ChannelList from "./component/ChannelList";

import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import GroupChannelList from "@sendbird/uikit-react/GroupChannelList";
import GroupChannelListHeader from "@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader";

import "./component/loading.css";
let sb;
const APP_ID = "B72515CE-E0DA-417F-AF02-4BF3D07B3D50";

function Chat() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);
  const [state, updateState] = useState({
    applicationUsers: [],
    groupChannelMembers: [],
    currentlyJoinedChannel: null,
    messages: [],
    channels: [],
    messageInputValue: "",
    userIdInputValue: userData?.dooNineId,
    channelNameUpdateValue: "",
    settingUpUser: true,
    file: null,
    messageToUpdate: null,
    messageCollection: null,
    loading: false,
    error: false,
  });

  const [searchInput, setSearchInput] = useState("");
  const [filteredChannels, setFilteredChannels] = useState([]);
  const [inviteChannel, setInviteChannel] = useState(null);

  const stateRef = useRef();
  stateRef.current = state;

  const setupUser = async () => {
    const { userIdInputValue } = state;

    // ตรวจสอบว่ามี instance ของ Sendbird หรือไม่
    if (sb) {
      try {
        await sb.disconnect(); // Disconnect user เพื่อ clear ข้อมูลเก่า
        sb = null; // ลบ instance
      } catch (error) {
        console.log("Error disconnecting Sendbird:", error);
      }
    }

    const sendbirdChat = await SendbirdChat.init({
      appId: APP_ID,
      localCacheEnabled: false, // ปิด cache
      modules: [new GroupChannelModule()],
    });

    await sendbirdChat.connect(
      userData?.dooNineId,
      userData?.sendBirdAccessToken
    );
    await sendbirdChat.setChannelInvitationPreference(true);

    sb = sendbirdChat;
    updateState({ ...state, loading: true });
    const [channels, error] = await loadChannels(channelHandlers);

    if (error) {
      return onError(error);
    }

    const sortedChannels = channels.sort((a, b) => {
      if (a.createdAt === undefined) return -1; // ถ้า a.createdAt เป็น undefined ให้อยู่ด้านบน
      if (b.createdAt === undefined) return 1; // ถ้า b.createdAt เป็น undefined ให้อยู่ด้านล่าง
      return b.createdAt - a.createdAt; // ถ้า createdAt ทั้งสองมีค่า ให้เรียงจากเวลาที่มากไปน้อย
    });

    updateState({
      ...state,
      channels: sortedChannels,
      loading: false,
      settingUpUser: false,
    });
  };

  const channelHandlers = {
    onChannelsAdded: (context, channels) => {
      const updatedChannels = [...channels, ...stateRef.current.channels];
      updateState({
        ...stateRef.current,
        channels: updatedChannels,
        applicationUsers: [],
      });
    },
    onChannelsDeleted: (context, channels) => {
      const updatedChannels = stateRef.current.channels.filter((channel) => {
        return !channels.includes(channel.url);
      });
      updateState({ ...stateRef.current, channels: updatedChannels });
    },
    onChannelsUpdated: (context, channels) => {
      const updatedChannels = stateRef.current.channels.map((channel) => {
        const updatedChannel = channels.find(
          (incomingChannel) => incomingChannel.url === channel.url
        );
        if (updatedChannel) {
          return updatedChannel;
        } else {
          return channel;
        }
      });

      updateState({ ...stateRef.current, channels: updatedChannels });
    },
  };

  const loadChannels = async (channelHandlers) => {
    // สร้าง filter โดยเปิดใช้งาน includeEmpty
    const groupChannelFilter = new GroupChannelFilter();
    groupChannelFilter.includeEmpty = true; // ให้รวมแชนแนลที่ไม่มีข้อความ

    // สร้าง collection โดยไม่ระบุเงื่อนไขการจัดลำดับ (optional)
    const collection = sb.groupChannel.createGroupChannelCollection({
      filter: groupChannelFilter, // ใช้ filter ที่รวมทุกแชนแนล
    });

    collection.setGroupChannelCollectionHandler(channelHandlers);

    const channels = await collection.loadMore();
    return [channels, null];
  };

  const onError = (error) => {
    updateState({ ...state, error: error.message });
    console.log(error);
  };

  const ChannelName = ({ members, channel }) => {
    const membersToDisplay = members
      .slice(0, 2)
      .filter((member) => member.userId !== userData.dooNineId);
    const membersNotToDisplay = members.slice(2);
    return (
      <>
        {membersToDisplay.map((member) => {
          return <span key={member.userId}>{member.nickname}</span>;
        })}
        {membersNotToDisplay.length > 0 && `+ ${membersNotToDisplay.length}`}
      </>
    );
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchInput(searchTerm);

    const results = state.channels.filter((channel) => {
      // Search by channel name
      const channelNameMatch = channel._name.toLowerCase().includes(searchTerm);

      // Search by member names
      const membersMatch = channel.members.some((member) =>
        member.nickname.toLowerCase().includes(searchTerm)
      );

      // Search by last message content
      const lastMessageMatch = channel.lastMessage?.message
        ?.toLowerCase()
        .includes(searchTerm);

      return channelNameMatch || membersMatch || lastMessageMatch;
    });

    setFilteredChannels(results);
  };

  useEffect(() => {
    if (userData) {
      setupUser();
    }
  }, [navigate, userData]);

  const colorSet = {
    "--sendbird-light-primary-500": "#ee3d7f",
    "--sendbird-light-primary-400": "#f16398",
    "--sendbird-light-primary-300": "#ee3d7f",
    "--sendbird-light-primary-200": "#f8b1cb",
    "--sendbird-light-primary-100": "#fbd8e5",
  };

  useEffect(() => {
    const filteredMembers = state.channels.flatMap((channel) =>
      channel.members.filter(
        (member) =>
          member.userId === userData.dooNineId && member.state !== "joined"
      )
    );
    setInviteChannel(filteredMembers);
  }, [state.channels]);

  console.log(state.channels);

  return (
    <Fragment>
      <Header />
      <Leftnav />
      <Rightchat />
      <SendbirdProvider
        appId={"B72515CE-E0DA-417F-AF02-4BF3D07B3D50"}
        userId={userData?.dooNineId}
        accessToken={userData?.sendBirdAccessToken}
        // breakpoint={true}
        // theme="dark"
        colorSet={colorSet}
      >
        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12 pageMyFriend">
                  <div className="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
                    <div className="d-flex w-100 flex-column flex-md-row ">
                      <h2 className="d-flex fw-700 mb-2 mb-md-0 mt-0 font-md text-grey-900 align-items-center">
                        Chat (
                        {searchInput
                          ? filteredChannels.length
                          : state.channels.length}
                        )
                      </h2>

                      <div className="d-flex align-items-center ms-md-auto mt-2 mt-md-0 w-md-auto">
                        <div className="d-flex align-items-center w-100">
                          <div className="search-form-2 flex-grow-1">
                            <i className="ti-search font-xss"></i>
                            <input
                              type="text"
                              className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                              placeholder="Search here."
                              style={{ flexGrow: 1, minWidth: "150px" }}
                              value={searchInput}
                              onChange={handleSearch}
                            />
                          </div>
                        </div>

                        <a
                          onClick={() => navigate("/create-chat")}
                          id="add-friend-btn"
                          className="btn-round-md ms-2 bg-greylight theme-dark-bg rounded-3 d-flex align-items-center justify-content-center"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="feather-plus font-xss text-grey-500"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 pageMyFriend">
                  <div className="d-flex w-100 flex-column flex-md-row">
                    <div
                      className="scroll-bar"
                      style={{
                        ...containerStyle,
                        // overflowY: "auto",
                        // height: "65vh",
                      }}
                    >
                      {inviteChannel && inviteChannel.length > 0 && (
                        <div
                          style={{ ...chatItemStyle, cursor: "pointer" }}
                          onClick={() => navigate("/invite")}
                        >
                          <div style={userInfoStyle}>
                            <div style={userTextContainerStyle}>
                              <h5 className="fw-600 mb-1">
                                <i
                                  className="feather-send font-xss me-2"
                                  style={{
                                    color: "#ee3d7f",
                                  }}
                                ></i>
                                You have{" "}
                                <span
                                  style={{
                                    color: "#ee3d7f",
                                    display: "inline",
                                  }}
                                >
                                  {inviteChannel.length}
                                </span>{" "}
                                invitations to join the chat group.
                              </h5>
                            </div>
                          </div>
                        </div>
                      )}
                      {(searchInput ? filteredChannels : state.channels).map(
                        (channel) => (
                          <ChannelList key={channel.url} channel={channel} />
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SendbirdProvider>
      <Popupchat />
      <Appfooter />
    </Fragment>
  );
}

export default Chat;

const formatDate = (createdAt) => {
  const date = new Date(createdAt);
  const now = new Date();

  // คำนวณความแตกต่างของเวลาในวัน
  const timeDifference = now - date;
  const oneDay = 24 * 60 * 60 * 1000; // หนึ่งวันในมิลลิวินาที

  if (timeDifference < oneDay && date.getDate() === now.getDate()) {
    // ถ้าเป็นวันนี้ แสดงเวลา
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${formattedMinutes} ${period}`;
  } else if (
    timeDifference < 2 * oneDay &&
    now.getDate() - date.getDate() === 1
  ) {
    // ถ้าเป็นเมื่อวาน แสดง "Yesterday"
    return "Yesterday";
  } else {
    // แสดงวันที่ในรูปแบบเดือน/วัน
    const options = { month: "short", day: "numeric" }; // เดือนแบบสั้น เช่น "Aug"
    return date.toLocaleDateString("en-US", options);
  }
};
