import React, { useEffect, useState } from "react";

import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";

import {
  containerStyle,
  searchContainerStyle,
  searchInputStyle,
  chatItemStyle,
  userInfoStyle,
  avatarStyle,
  userTextContainerStyle,
  nameStyle,
  messagePreviewStyle,
  timeStyle,
  notificationBubbleStyle,
} from "./StyleComponent";

import { useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import ReactDOM from "react-dom";
import Swal from "sweetalert2";
import axios from "axios";

const menu = "assets/images/message/Chat-Menu.svg";
const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;
const ChannelInviteList = ({ channel }) => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);

  const handleJoinChannel = async (channelURL) => {
    try {
      const response = await axios.put(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelURL}/accept`,
        { user_id: userData.dooNineId },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );
      //   console.log("Joined successfully:", response.data);

      // messege
      const response2 = await axios.post(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelURL}/messages`,
        {
          message_type: "MESG",
          user_id: userData.dooNineId,
          message: `${userData?.firstName} ${userData?.lastName} joined to the group chat.`,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Joined successfully",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      if (error.response) {
        console.error("Failed to join group chat:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleDenyChannel = async (channelURL) => {
    try {
      const response = await axios.put(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${channelURL}/decline`,
        { user_id: userData.dooNineId },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Denied successfully",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        //refresh
        window.location.reload();
      }); 
    } catch (error) {
      if (error.response) {
        console.error("Failed to join group chat:", error.response.data);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <>
      <div key={channel._url} style={{ ...chatItemStyle }}>
        <div style={userInfoStyle}>
          <div style={{ position: "relative" }}>
            <img
              src={channel?.coverUrl}
              alt="chat cover"
              style={{
                ...avatarStyle,
                outline: "3px solid #81d77a",
              }}
            />
          </div>
          <div style={userTextContainerStyle}>
            <h5
              className="fw-600 mb-1"
              style={{
                width: "38vw",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {`${channel?._name} (${channel?.memberCount})`}
            </h5>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <button
            className="btn bg-current text-white mx-1 fs-5"
            onClick={() => handleJoinChannel(channel._url)}
          >
            Join
          </button>
          <button
            className="btn btn-light mx-1 fs-5"
            onClick={() => handleDenyChannel(channel._url)}
          >
            Deny
          </button>
        </div>
      </div>
    </>
  );
};

export default ChannelInviteList;
