import React, { useEffect, useState } from "react";

import Header from "../../../components/Header";
import Leftnav from "../../../components/Leftnav";
import Rightchat from "../../../components/Rightchat";
import Appfooter from "../../../components/Appfooter";
import Popupchat from "../../../components/Popupchat";

import CreateRound from "../component/CreateRoundCard";
import RoundsCount from "./RoundsCount";
import RoundFinished from "./finishedround/RoundFinished";
import RoundUpcoming from "./upcominground/RoundUpcoming";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import {
  collection,
  query,
  onSnapshot,
  where,
  getDocs,
  or,
  and,
  orderBy,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";
import { useSelector } from "react-redux";
import RoundStart from "../roundactivity/startround/RoundStart";

import RoundRequest from "../roundactivity/requestround/RoundRequest";
// import FriendActivity from "../roundactivity/friendactivity/FriendActivity";
import { epochToDateTime } from "../component/wizard/module/covertTime";

function RoundActivity() {
  const [activeSectionRound, setActiveSectionRound] = useState("upcoming");
  const [listRound, setListRound] = useState([]);
  const userData = useSelector((state) => state.user.data);

  // useEffect(() => {
  //   if (userData) {
  //     const unsubscribe = onSnapshot(query(collection(firestore, "round")), (roundSnapshot) => {
  //       let roundDataArray = [];

  //       roundSnapshot.forEach((doc) => {
  //         const roundData = doc.data();

  //         const isCreator = roundData.creator === userData.uid;
  //         const isUserInList = roundData.userList.some(
  //           (user) =>
  //             (user.player?.uid === userData.uid && user.player?.isJoin === true) ||
  //             (user.caddie?.uid === userData.uid && user.caddie?.isJoin === true)
  //         );

  //         if (isCreator || isUserInList) {
  //           if (roundData.teeTime === "now") {
  //             roundDataArray.push({ ...roundData, roundId: doc.id });
  //           }
  //         }
  //       });

  //       setListRound(roundDataArray);
  //     });

  //     return () => unsubscribe();
  //   }
  // }, [userData]);

  useEffect(() => {
    let unsubscribe = () => {};

    let fetchData = async () => {
      if (!userData) return;

      try {
        const fetchScoreDetails = async (roundId, playerUids) => {
          if (playerUids.length === 0) return [];

          const userQuery = query(
            collection(firestore, "scoreCards"),
            where("userId", "in", playerUids),
            where("roundId", "==", roundId)
          );
          const caddieQuery = query(
            collection(firestore, "scoreCards"),
            where("caddieId", "in", playerUids),
            where("roundId", "==", roundId)
          );

          const [userSnapshot, caddieSnapshot] = await Promise.all([
            getDocs(userQuery),
            getDocs(caddieQuery),
          ]);

          let scoreDetailsArray = [];

          userSnapshot.forEach((doc) => {
            scoreDetailsArray.push(doc.data());
          });

          caddieSnapshot.forEach((doc) => {
            scoreDetailsArray.push(doc.data());
          });

          return scoreDetailsArray;
        };

        const userQuery = query(
          collection(firestore, "round"),
          where("teeTime", "==", "now")
        );

        // Replace getDocs with onSnapshot for real-time updates
        unsubscribe = onSnapshot(userQuery, async (userSnapshot) => {
          let roundDataArray = [];

          const roundPromises = userSnapshot.docs.map(async (roundDoc) => {
            const roundData = roundDoc.data();
            const isCreator = roundData?.creator === userData?.uid;
            const isUserInList = roundData.userList.some(
              (user) =>
                (user.player?.uid === userData.uid &&
                  user.player?.isJoin === true) ||
                (user.caddie?.uid === userData.uid &&
                  user.caddie?.isJoin === true)
            );

            if (isCreator || isUserInList) {
              const playerUids = roundData.userList
                .map((user) => user.player?.uid || user.caddie?.uid)
                .filter((uid) => uid !== undefined);

              if (playerUids.length > 0) {
                const scoreDetailsArray = await fetchScoreDetails(
                  roundData.roundId,
                  playerUids
                );

                roundData.userList = roundData.userList.map((user) => {
                  const playerScore = scoreDetailsArray.find(
                    (details) => details.userId === user.player?.uid || details.caddieId === user.caddie?.uid
                  );
                  const status = playerScore ? playerScore.status : undefined;
                  return {
                    ...user,
                    player: { ...user.player, status },
                    caddie: { ...user.caddie, status },
                  };
                });

                const isRoundStatus = roundData.userList.some(
                  (user) =>
                    (user.player?.uid === userData.uid &&
                      ((user.player?.status !== "finished" &&
                        user.player?.status !== "unfinished") ||
                        user.player?.status === undefined)) ||
                    (user.caddie?.uid === userData.uid &&
                      ((user.caddie?.status !== "finished" &&
                        user.caddie?.status !== "unfinished") ||
                        user.caddie?.status === undefined))
                );

                if (isRoundStatus && roundData.status !== "deleted") {
                  roundDataArray.push({
                    ...roundData,
                    roundId: roundDoc.id,
                  });
                }
              }
            }
          });

          await Promise.all(roundPromises); // Ensure all data is processed
          setListRound(roundDataArray); // Update state with real-time data
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (userData) {
      fetchData();
    }

    return () => unsubscribe();
  }, [userData]);

  // console.log(userData)
  // console.log(listRound)
  return (
    <>
      <Header />
      <Leftnav />
      <Rightchat />

      {/* <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body mb-2">
              <div className="col-xl-8 col-xxl-9 col-lg-8 mb-2">
                {listRound.length > 0 ? (
                  <RoundStart />
                ) : (
                  <CreateRound />
                )}
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0" style={{ maxHeight: 600, overflow: 'auto' }}>
                <RoundRequest />
                <RoundRequest />

              </div>
            </div>

            <div className="row feed-body">
              <div className="col-xl-8 col-xxl-9 col-lg-8">
                <RoundsCount
                  activeSectionRound={activeSectionRound}
                  setActiveSectionRound={setActiveSectionRound}
                />
                {activeSectionRound === "upcoming" && <RoundUpcoming />}
                {activeSectionRound === "finished" && <RoundFinished />}
              </div>


            </div>
          </div>
        </div>
      </div> */}

      <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left">
            <div className="row feed-body mb-1">
              <div className="col-xl-8 col-xxl-9 col-lg-8 px-md-3 feed-home">
                {listRound.length > 0 ? <RoundStart /> : <CreateRound />}
                <div className="d-lg-none d-block col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
                  <RoundRequest />
                </div>
                <RoundsCount
                  activeSectionRound={activeSectionRound}
                  setActiveSectionRound={setActiveSectionRound}
                />
                {activeSectionRound === "upcoming" && <RoundUpcoming />}
                {activeSectionRound === "finished" && <RoundFinished />}
              </div>
              <div className="d-none d-lg-block col-xl-4 col-xxl-3 col-lg-4 ps-lg-0 right-scroll-bar">
                <RoundRequest />
                {/* <FriendActivity /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="main-content right-chat-active">
        <div className="middle-sidebar-bottom">
          <div className="middle-sidebar-left d-flex">
          <div className="row feed-body mb-2">

            <div className="col-xl-8 col-xxl-9 col-lg-8 mb-2 me-2">
              {listRound.length > 0 ? <RoundStart /> : <CreateRound />}

              <RoundsCount
                activeSectionRound={activeSectionRound}
                setActiveSectionRound={setActiveSectionRound}
              />
              {activeSectionRound === "upcoming" && <RoundUpcoming />}
              {activeSectionRound === "finished" && <RoundFinished />}
            </div>

            <div className="col-xl-4 col-xxl-3 col-lg-4 ps-lg-0">
              <RoundRequest />
            </div>
          </div>
          </div>
        </div>
      </div> */}
      <Popupchat />
      <Appfooter />
    </>
  );
}

export default RoundActivity;
