import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { firestore } from "../../../../components/firebase/FirebaseConfig";
import React, { useEffect, useState } from "react";
import { epochToDateTime } from "../../component/wizard/module/covertTime";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { denyRound } from "../module/denyRound";
import Spinner from "react-bootstrap/Spinner";

function FriendRequest() {
  const userData = useSelector((state) => state.user.data);
  const [listRoundRequest, setListRoundRequest] = useState([]);
  const [listRoundStart, setListRoundStart] = useState([]);
  const [loading, setLoading] = useState(true);
  const maxUserList = { desktop: 7, mobile: 5 };
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(firestore, "round")),
      async (userSnapshot) => {
        try {
          let roundDataArray = [];
          const fetchScoreDetails = async (uid, roundId) => {
            const userQuery = query(
              collection(firestore, "scoreCards"),
              where("userId", "==", uid),
              where("roundId", "==", roundId)
            );
            const caddieQuery = query(
              collection(firestore, "scoreCards"),
              where("caddieId", "==", uid),
              where("roundId", "==", roundId)
            );

            const [userSnapshot, caddieSnapshot] = await Promise.all([
              getDocs(userQuery),
              getDocs(caddieQuery),
            ]);

            let scoreDetails = {};

            userSnapshot.forEach((doc) => {
              scoreDetails = doc.data();
            });

            caddieSnapshot.forEach((doc) => {
              scoreDetails = doc.data();
            });

            return scoreDetails;
          };

          for (const roundDoc of userSnapshot.docs) {
            const roundData = roundDoc.data();
            const isCreator = roundData.creator === userData?.uid;
            const isUserInList =
              Array.isArray(roundData.userList) &&
              roundData.userList.some(
                (user) =>
                  (user.player?.uid === userData?.uid &&
                    user.player?.isJoin === true) ||
                  (user.caddie?.uid === userData?.uid &&
                    user.caddie?.isJoin === true)
              );

            if (isCreator || isUserInList) {
              const playerUids = (roundData.userList || []) // ตรวจสอบว่ามีค่าเป็นอาร์เรย์
                .map((user) => user.player?.uid || user.caddie?.uid) // ถ้าไม่มี player ใช้ caddie
                .filter((uid) => uid !== undefined);

              if (playerUids.length > 0) {
                const scoreDetailsArray = await Promise.all(
                  playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
                );

                roundData.userList = roundData.userList.map((user) => {
                  const activeUser = user.player ?? user.caddie; // ใช้ player ถ้ามี, ถ้าไม่มีใช้ caddie
                  if (activeUser?.uid) {
                    const playerScore = scoreDetailsArray.find(
                      (details) =>
                        details.userId === activeUser.uid ||
                        details.caddieId === activeUser.uid
                    );
                    const status = playerScore ? playerScore.status : undefined;
                    return {
                      ...user,
                      player: { ...user.player, status },
                      caddie: { ...user.caddie, status },
                    };
                  }
                  return user;
                });

                const isRoundStatus = roundData.userList.some(
                  (user) =>
                    (user.player?.uid === userData.uid &&
                      user.player !== null &&
                      (user.player?.status === "playing" ||
                        user.player?.status == undefined)) ||
                    (user.caddie?.uid === userData.uid &&
                      user.caddie !== null &&
                      (user.caddie?.status === "playing" ||
                        user.caddie?.status == undefined))
                );

                if (
                  isRoundStatus &&
                  roundData.teeTime === "now" &&
                  roundData.status !== "deleted"
                ) {
                  roundDataArray.push({
                    ...roundData,
                    roundId: roundDoc.id,
                  });
                }
              }
            }
          }

          setListRoundStart(roundDataArray);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    );

    return () => unsubscribe(); // Clean up the listener on unmount
  }, [userData]);

  useEffect(() => {
    if (!userData?.uid) return;
    setLoading(true);

    const fetchUserDetails = async (uid, userCache) => {
      if (userCache[uid]) return userCache[uid];

      const userQuery = query(
        collection(firestore, "users"),
        where("uid", "==", uid)
      );
      const userSnapshot = await getDocs(userQuery);
      let userDetails = {};
      userSnapshot.forEach((userDoc) => {
        userDetails = userDoc.data();
      });
      userCache[uid] = userDetails;
      return userDetails;
    };

    const fetchScoreDetails = async (uid, roundId) => {
      const userQuery = query(
        collection(firestore, "scoreCards"),
        where("userId", "==", uid),
        where("roundId", "==", roundId)
      );
      const caddieQuery = query(
        collection(firestore, "scoreCards"),
        where("caddieId", "==", uid),
        where("roundId", "==", roundId)
      );

      const [userSnapshot, caddieSnapshot] = await Promise.all([
        getDocs(userQuery),
        getDocs(caddieQuery),
      ]);

      let scoreDetails = {};

      userSnapshot.forEach((doc) => {
        scoreDetails = doc.data();
      });

      caddieSnapshot.forEach((doc) => {
        scoreDetails = doc.data();
      });

      return scoreDetails;
    };

    const fetchRoundActivity = async () => {
      const roundActivityQuery = query(
        collection(firestore, "roundActivity"),
        where("userList", "array-contains", userData.uid),
        where("creator", "!=", userData.uid),
        orderBy("created", "desc")
      );

      const roundActivitySnapshot = await getDocs(roundActivityQuery);
      if (roundActivitySnapshot.empty) return setListRoundRequest([]);

      const rounds = roundActivitySnapshot.docs.map((doc) => doc.data());
      const dataArray = [];
      const userCache = {};

      for (const roundActivity of rounds) {
        const { roundId } = roundActivity;

        const roundQuery = query(
          collection(firestore, "round"),
          where("roundId", "==", roundId),
          orderBy("created", "desc"),
          where("teeTime", "!=", "finished")
        );

        const roundSnapshot = await getDocs(roundQuery);
        if (roundSnapshot.empty) continue;

        const roundDoc = roundSnapshot.docs[0];
        const round = roundDoc.data();

        let isJoinTrue = false;
        const userChecks = round.userList.map(({ player, caddie }) => {
          if (player?.uid === userData.uid && player.isJoin) isJoinTrue = true;
          if (caddie?.uid === userData.uid && caddie.isJoin) isJoinTrue = true;
        });

        await Promise.all(userChecks);

        if (!isJoinTrue && round.scheduleTime) {
          let holesCourse = "";
          let courseData = null;

          // Check if round.holesCourse exists before querying
          if (round.holesCourse) {
            const courseHolesRef = doc(
              firestore,
              "courseHoles",
              round.holesCourse
            );
            const courseHolesSnapshot = await getDoc(courseHolesRef);
            if (courseHolesSnapshot.exists()) {
              holesCourse = courseHolesSnapshot.data().courseHoleName;
            }
          }

          const courseQuery = query(
            collection(firestore, "courses"),
            where("id", "==", round.course)
          );
          const courseSnapshot = await getDocs(courseQuery);
          courseData = courseSnapshot.docs[0]?.data() || {};

          const { time, day, month } = epochToDateTime(round.scheduleTime);

          const userFetches = round.userList.map(async ({ player, caddie }) => {
            const userDataArray = [];
            if (player?.uid) {
              const playerData = await fetchUserDetails(player.uid, userCache);
              userDataArray.push({
                ...playerData,
                isCreator: player.isCreator,
                isJoin: player.isJoin,
              });
            }
            if (caddie?.uid) {
              const caddieData = await fetchUserDetails(caddie.uid, userCache);
              userDataArray.push({
                ...caddieData,
                isCreator: caddie.isCreator,
                isJoin: caddie.isJoin,
                playerConfirm: caddie.playerConfirm,
              });
            }
            return userDataArray;
          });

          const userDataArray = await Promise.all(userFetches);

          const playerUids = round.userList
            .map((user) => user.player?.uid || user.caddie?.uid) // ถ้าไม่มี player ใช้ caddie
            .filter((uid) => uid !== undefined);

          if (playerUids.length > 0) {
            const scoreDetailsArray = await Promise.all(
              playerUids.map((uid) => fetchScoreDetails(uid, roundDoc.id))
            );

            round.userList = round.userList.map((user) => {
              const activeUser = user.player ?? user.caddie; // ใช้ player ถ้ามี, ถ้าไม่มีใช้ caddie
              if (activeUser?.uid) {
                const playerScore = scoreDetailsArray.find(
                  (details) =>
                    details.userId === activeUser.uid ||
                    details.caddieId === activeUser.uid
                );
                const status = playerScore ? playerScore.status : undefined;
                return {
                  ...user,
                  player: { ...user.player, status },
                  caddie: { ...user.caddie, status },
                };
              }
              return user;
            });

            const isRoundStatus = round.userList.some(
              (user) =>
                (user.player?.uid === userData.uid &&
                  (user.player?.status === "playing" ||
                    user.player?.status === undefined)) ||
                (user.caddie?.uid === userData.uid &&
                  user.caddie?.status === "playing") ||
                user.caddie?.status === undefined
            );
            courseSnapshot.forEach((courseDoc) => {
              const courseData = courseDoc.data();
              if (isRoundStatus && round.status !== "deleted") {
                dataArray.push({
                  ...round,
                  courseInfo: courseData,
                  time,
                  day,
                  month,
                  userData: userDataArray.flat(),
                  holesCourse,
                });
              }
            });
          }
          // courseSnapshot.forEach((courseDoc) => {
          //   const courseData = courseDoc.data();
          //   dataArray.push({
          //     ...round,
          //     courseInfo: courseData,
          //     time,
          //     day,
          //     month,
          //     userData: userDataArray.flat(),
          //     holesCourse,
          //   });
          // });
        }
      }

      setListRoundRequest(dataArray);
      setLoading(false);
    };

    fetchRoundActivity();
  }, [userData]);

  // console.log(listRoundStart);

  const handleJoinClick = (roundId, teeTime) => {
    if (listRoundStart.length > 0 && teeTime == "now") {
      Swal.fire({
        title: "Round In Progress",
        text: "You still have a round in progress. Please finish that round first.",
        icon: "warning",
        confirmButtonColor: "#ee3d7f",
        confirmButtonText: "OK",
      });
    } else {
      navigate(`/join-round?id=${roundId}`);
    }
  };

  const handleDeny = async (roundId, userId) => {
    try {
      await denyRound(roundId, userId);
      console.log("Round denied successfully");
    } catch (error) {
      console.error("Error denying round:", error);
    }
  };

  return (
    <>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "300px" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        (() => {
          // Filter rounds based on the user's role and join status
          const filteredRounds = listRoundRequest.filter((round) =>
            round.userData.some((user) => {
              if (user.uid === userData.uid) {
                if (user.role === "player") {
                  return user.isJoin === false;
                } else if (user.role === "caddie") {
                  return user.playerConfirm === true;
                }
              }
              return false;
            })
          );

          return filteredRounds.length > 0 ? (
            filteredRounds
              .sort(
                (a, b) => new Date(b.scheduleTime) - new Date(a.scheduleTime)
              )
              .map((round, index) => (
                <div key={index} className="create-round-req-main">
                  <section className="create-round-req-head">
                    <div className="pointer">
                      <h4 className="fw-600">{round.roundName}</h4>
                      <br />
                      <h6 className="fw-600 text-muted">
                        {`${round.holes} (${round.holesCourse}), ${round.gameMode}(${round.scoring})`}
                      </h6>
                    </div>
                  </section>
                  <section
                    className="create-round-req-body p-2 mb-2 pointer"
                    style={{
                      backgroundImage: round.coverImage
                        ? `url(${
                            typeof round.coverImage === "string"
                              ? round.coverImage
                              : URL.createObjectURL(round.coverImage)
                          })`
                        : "none",
                      height: round.coverImage ? "" : "inherit",
                    }}
                  >
                    <div
                      className="req-course-data px-0 row"
                      style={{
                        backgroundColor: round.coverImage ? "" : "#fff",
                      }}
                    >
                      <div className="col-sm-2 col-3 pointer p-0 d-flex justify-content-center align-items-center">
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={70}
                          height={70}
                          className="rounded-circle my-2 d-sm-block d-none"
                        />
                        <img
                          src={round.courseInfo.profileImage}
                          alt="course-profile"
                          width={50}
                          height={50}
                          className="rounded-circle my-2 d-sm-none d-block"
                        />
                      </div>
                      <div className="col-sm-8 col-6 pointer p-0 d-flex justify-content-center align-items-center">
                        <div>
                          <span className="fw-600 fs-4 d-sm-block d-none">
                            {round.courseInfo.courseName}
                          </span>
                          <span className="fw-600 fs-5 d-sm-none d-block">
                            {round.courseInfo.courseName}
                          </span>
                          <span className="fw-600 fs-5 d-sm-none d-block">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                          <span className="fw-600 fs-6 d-sm-block d-none">{`${round.courseInfo.location.districtEng}, ${round.courseInfo.location.provinceEng}`}</span>
                        </div>
                      </div>
                      <div className="col-sm-2 col-3 px-0 px-sm-20">
                        <div
                          className="pointer rounded-3 h-100 d-flex justify-content-center align-items-center flex-column"
                          style={{
                            backgroundColor:
                              round.teeTime === "schedule"
                                ? "#E59E49"
                                : "#81D77A",
                          }}
                        >
                          <h4 className="fw-600 text-white text-center">
                            {round.month}
                          </h4>
                          <h1 className="fw-700 text-white text-center">
                            {round.day}
                          </h1>
                          <div className="bg-white text-center w-100">
                            <span
                              className="fw-600 fs-4"
                              style={{
                                color:
                                  round.teeTime === "schedule"
                                    ? "#E59E49"
                                    : "#81D77A",
                              }}
                            >
                              {round.time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section>
                    {/* Desktop display with 7 user limit */}
                    <div className="d-none d-sm-flex align-items-center justify-content-between p-3 pointer">
                      <div className="d-flex align-items-center">
                        {round.userData
                          .slice(0, maxUserList.desktop)
                          .map((user, idx) => (
                            <img
                              key={idx}
                              src={user.profileImage}
                              alt={user.role}
                              width={50}
                              height={50}
                              className={`rounded-circle ${
                                user.role
                              }-color-outline ${user.isCreator ? "me-4" : ""}`}
                              style={{
                                marginLeft: user.isCreator ? "" : "-10px",
                                zIndex: maxUserList.desktop - idx,
                              }}
                            />
                          ))}
                        {round.userData.length > maxUserList.desktop && (
                          <div
                            className="d-flex justify-content-center align-items-center rounded-circle bg-light theme-light-bg p-1"
                            style={{
                              width: 50,
                              height: 50,
                              opacity: 0.6,
                            }}
                          >
                            <h4>
                              +{round.userData.length - maxUserList.desktop}{" "}
                            </h4>
                          </div>
                        )}
                      </div>

                      {/* Right side buttons */}
                      <div className="d-flex justify-content-end">
                        <button
                          className="p-2 lh-20 w100 bg-current me-2 text-white text-center font-xssss fw-600 ls-1 rounded-xl border border-0"
                          onClick={() =>
                            handleJoinClick(round.roundId, round.teeTime)
                          }
                        >
                          Join
                        </button>
                        <button
                          className="p-2 lh-20 w100 text-center text-black font-xssss fw-600 ls-1 rounded-xl border border-0"
                          style={{ background: "#f5f5f5" }}
                          onClick={() =>
                            handleDeny(round.roundId, userData.uid)
                          }
                        >
                          Deny
                        </button>
                      </div>
                    </div>
                  </section>
                </div>
              ))
          ) : (
            <h5 className="text-center p-2">No rounds available.</h5>
          );
        })()
      )}
    </>
  );
}
export default FriendRequest;
