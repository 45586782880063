import React, { Fragment, useState, useEffect, useRef } from "react";

import { useSelector } from "react-redux";
import axios from "axios";
import { collection, query, where, getDocs, or, and } from "firebase/firestore";
import { firestore } from "../../../components/firebase/FirebaseConfig";
import { ReactSVG } from "react-svg";

import { useNavigate } from "react-router-dom";

import { Modal } from "react-bootstrap";
import { ListGroup } from "react-bootstrap";
import { ChatTo } from "../../../components/chat/Chat";
import Swal from "sweetalert2";

const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

function CreateGroupChatBody() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.user.data);

  const [modalShowFriend, setModalShowFriend] = useState(false);
  const [playerList, setPlayerList] = useState([]);
  const [selectFriends, setSelectFriends] = useState([]);
  const [groupChannelName, setGroupChannelName] = useState("");

  const handleCloseModalShowFriend = () => {
    setModalShowFriend(false);
  };

  const [loadingUser, setLoadingUser] = useState(true);

  const [filteredPlayerList, setFilteredPlayerList] = useState([]); // Add this for the filtered list
  const [searchQuery, setSearchQuery] = useState(""); // State for search query

  const playerIdRef = useRef([]);
  const favPlayerRef = useRef([]);
  const friendPlayerRef = useRef([]);
  const PlayerDataRef = useRef([]);

  useEffect(() => {
    async function getYourfriend() {
      friendPlayerRef.current = [];
      try {
        const friendCollection = collection(firestore, "friendRequests");
        const q = query(
          friendCollection,
          or(
            and(
              where("fromUserId", "==", userData?.uid),
              where("status", "==", "accept")
            ),
            and(
              where("toUserId", "==", userData?.uid),
              where("status", "==", "accept")
            )
          )
        );
        const friendSnapshot = await getDocs(q);
        if (!friendSnapshot.empty) {
          friendSnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.toUserId === userData?.uid) {
              friendPlayerRef.current.push(data.fromUserId);
            } else {
              friendPlayerRef.current.push(data.toUserId);
            }
          });
        }

        await getYourFav();
        await getPlayerData();
        sortPlayer();
        setLoadingUser(false);
      } catch (error) {
        setLoadingUser(false);
        console.error("Fail to get friend caddie", error);
      }
    }

    const getYourFav = async () => {
      favPlayerRef.current = [];
      try {
        const favCollection = collection(firestore, "favoritePlayers");
        const q = query(
          favCollection,
          where("userId", "==", userData.uid),
          where("status", "==", "active")
        );
        const favSnapshot = await getDocs(q);
        if (!favSnapshot.empty) {
          favSnapshot.forEach((doc) => {
            const data = doc.data();
            if (!favPlayerRef.current.includes(data.playerId)) {
              favPlayerRef.current.push(data.playerId);
            }
          });
        }

        const favCollectionFriends = collection(firestore, "favoriteFriends");
        const qFriends = query(
          favCollectionFriends,
          where("userId", "==", userData.uid),
          where("status", "==", "active")
        );
        const favSnapshotFriends = await getDocs(qFriends);
        if (!favSnapshotFriends.empty) {
          favSnapshotFriends.forEach((doc) => {
            const data = doc.data();
            if (!favPlayerRef.current.includes(data.caddieId)) {
              favPlayerRef.current.push(data.caddieId);
            }
          });
        }
      } catch (error) {
        console.error("Fail to get fav caddie", error);
      }
    };

    const getPlayerData = async (playerList = [...friendPlayerRef.current]) => {
      if (!playerList.length) return;

      playerIdRef.current = playerList;
      const playerDataListArray = [];
      try {
        const userCollection = collection(firestore, "users");
        const q = query(userCollection, where("uid", "in", playerList));
        const userSnapshot = await getDocs(q);
        if (!userSnapshot.empty) {
          userSnapshot.forEach((doc) => {
            playerDataListArray.push(doc.data());
          });
          PlayerDataRef.current = playerDataListArray;
          // sortCaddie()
        }
      } catch (error) {
        console.error("Fail to get caddie data", error);
      }
    };

    function sortPlayer() {
      const updatedPlayerDataList = PlayerDataRef.current.map((player) => {
        return {
          ...player,
          isFav: favPlayerRef.current.includes(player.uid),
          isFriend: friendPlayerRef.current.includes(player.uid),
        };
      });
      // console.log(updatedPlayerDataList);

      setPlayerList(() => {
        return updatedPlayerDataList.sort((a, b) => {
          if (a.isFav !== b.isFav) {
            return b.isFav - a.isFav;
          }
          if (a.isFriend !== b.isFriend) {
            return b.isFriend - a.isFriend;
          }
          return 0;
        });
      });
    }

    getYourfriend();
  }, [userData]);

  const toggleFriendSelection = (friend) => {
    setSelectFriends((prev) => {
      const isAlreadySelected = prev.find((f) => f.uid === friend.uid);
      if (isAlreadySelected) {
        return prev.filter((f) => f.uid !== friend.uid);
      } else {
        return [...prev, friend];
      }
    });
  };

  const handleStarGroupChat = async () => {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const dooNineIds = selectFriends
      .filter((person) => person.dooNineId !== userData.dooNineId) // Exclude the specific dooNineId
      .map((person) => person.dooNineId); // Extract remaining dooNineId values

    let response = "";
    try {
      // create
      response = await axios.post(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels`,
        {
          name: groupChannelName,
          user_ids: [userData.dooNineId],
          operator_ids: [userData.dooNineId],
          auto_accept: false,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      // messege
      const response2 = await axios.post(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${response.data.channel_url}/messages`,
        {
          message_type: "MESG",
          user_id: userData.dooNineId,
          message: `Welcome to the group chat ${groupChannelName}.`,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );

      // disable auto accept
      for (const ids of dooNineIds) {
        const response3 = await axios.put(
          `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/${ids}/channel_invitation_preference`,
          {
            auto_accept: false,
          },
          {
            headers: {
              "Content-Type": "application/json; charset=utf8",
              "Api-Token": sendBird_api_token,
            },
          }
        );
        console.log(response3);
      }

      // invite
      const response4 = await axios.post(
        `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${response.data.channel_url}/invite`,
        {
          user_ids: dooNineIds,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            "Api-Token": sendBird_api_token,
          },
        }
      );
      console.log(response4);

      if (response4.status === 200) {
        Swal.fire({
          title: "Success",
          html: `<h5 class="fw-600">Create group chat complete.</h5>`,
          icon: "success",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/chat-room?channelId=" + response.data.channel_url);
        });
      } else {
        Swal.fire({
          title: "Error",
          html: `<h5 class="fw-600">Error in creating group chat</h5>`,
          icon: "error",
          confirmButtonColor: "#ee3d7f",
          confirmButtonText: "OK",
        });

        console.log("Error in creating group chat");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Initial fetch or update the filtered list whenever playerList changes
    setFilteredPlayerList(playerList);
  }, [playerList]);

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    // Filter the player list based on the query
    const filtered = playerList.filter((player) => {
      const fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
      return fullName.includes(query);
    });
    setFilteredPlayerList(filtered);
  };

  return (
    <>
      <div className="mb-3">
        <h5 className="mt-4 mb-1">
          <strong>Group Chat Name</strong>
        </h5>
        <input
          type="text"
          name="round-name"
          id="round-name"
          className="form-control"
          value={groupChannelName}
          onChange={(e) => setGroupChannelName(e.target.value)}
        />
      </div>
      <div>
        <h5 className="mt-4 mb-1">
          <strong>Select Member</strong>
        </h5>
        <div className="card mb-3 border-0 main-step-two-card">
          <div className="card main-step-two-card-body bg-white d-block d-sm-grid theme-dark-bg my-1">
            <div className="player-container theme-light-bg d-flex align-items-center flex-wrap p-2">
              <figure className="d-flex align-items-center mb-0 ms-3">
                <img
                  alt="user"
                  src={userData?.profileImage}
                  className={`user-img ${
                    userData?.role == "player"
                      ? "player-color-outline"
                      : "caddie-color-outline"
                  }`}
                />
                <h5 className="fw-600 ms-1">
                  {`${userData?.firstName} ${userData?.lastName}`}
                  <span>
                    <h6 className="fw-600 text-muted">Operator</h6>
                  </span>
                </h5>
              </figure>
            </div>

            {selectFriends.length === 0 ? null : (
              <>
                {selectFriends.map((friend) => (
                  <div className="player-container theme-light-bg d-flex align-items-center justify-content-between p-2">
                    <figure className="d-flex align-items-center mb-1 ms-3">
                      <img
                        alt="user"
                        src={friend?.profileImage}
                        className={`user-img ${
                          friend?.role == "player"
                            ? "player-color-outline"
                            : "caddie-color-outline"
                        }`}
                      />
                      <h5 className="fw-600 ms-1">{`${friend?.firstName} ${friend?.lastName}`}</h5>
                    </figure>
                    <div className="col-3 text-end">
                      <ReactSVG
                        onClick={() => toggleFriendSelection(friend)}
                        className="pointer"
                        src="assets/images/dooNineIconV3/create-round/createRound-remove.svg"
                        wrapper="svg"
                        width={35}
                        height={35}
                      />
                    </div>
                  </div>
                ))}
              </>
            )}

            <div className="player-container-none theme-light-bg">
              <ReactSVG
                onClick={() => setModalShowFriend(true)}
                src="assets/images/dooNineIconV3/create-round/createRound-addPlayer_w.svg"
                wrapper="svg"
                width={50}
                height={50}
                className="pointer"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="text-start">
          <button
            className="btn bg-current text-white mx-1 fs-5"
            disabled={selectFriends.length === 0}
            onClick={() => handleStarGroupChat()}
          >
            Start Group Chat
          </button>
          <button
            className="btn btn-light mx-1 fs-5"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
        </div>
      </div>

      <Modal
        show={modalShowFriend}
        onHide={handleCloseModalShowFriend}
        centered
        animation={false}
      >
        <Modal.Header>
          <Modal.Title className="d-flex justify-content-between align-items-center gap-4 w-100">
            <h2 className="fw-600 text-nowrap">Select Friend</h2>
            <div className="search-form-2 flex-grow-1">
              <i className="ti-search font-xss"></i>
              <input
                type="text"
                className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                placeholder="Search here."
                value={searchQuery}
                onChange={handleSearchChange} // Update search query
              />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="right-scroll-bar mb-3" style={{ maxHeight: "60vh" }}>
            <ListGroup>
              {filteredPlayerList.map((player, index) => (
                <ListGroup.Item
                  key={index}
                  className="sel-player-item pointer"
                  style={{
                    backgroundColor: selectFriends.some(
                      (f) => f.uid === player.uid
                    )
                      ? "#FCD8E5"
                      : "",
                  }}
                  onClick={() => toggleFriendSelection(player)}
                >
                  <div className="d-flex align-items-center">
                    <div className="col-3">
                      <img
                        src={player.profileImage}
                        alt={`user${index}`}
                        width={50}
                        height={50}
                        className={`rounded-circle ${
                          player?.role === "player"
                            ? "player-color-outline"
                            : "caddie-color-outline"
                        }`}
                        style={{ objectFit: "cover" }}
                      />
                    </div>
                    <div className="col-6">
                      <h4 className="fw-600">{`${player.firstName} ${player.lastName}`}</h4>
                    </div>
                    <div className="col-3 text-end">
                      {player.isFav ? (
                        <ReactSVG
                          src="assets/images/dooNineIconV3/create-round/createRound-favCaddie.svg"
                          wrapper="svg"
                          width={30}
                          height={30}
                        />
                      ) : null}
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>

          <div className="col-lg-12">
            <div className="text-end">
              <button
                onClick={handleCloseModalShowFriend}
                className="btn btn-light mx-1 fs-5"
              >
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateGroupChatBody;
