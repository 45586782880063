import React, { useState, useEffect, useRef } from "react";
import Header from '../../components/Header';
import Leftnav from '../../components/Leftnav';
import Rightchat from '../../components/Rightchat';
import Appfooter from '../../components/Appfooter';
import Popupchat from '../../components/Popupchat';

// import Profiledetail from '../../components/Profiledetail';
// import Profilephoto from '../../components/Profilephoto';
// import Events from '../../components/Events';

import CreatePost from "../home/createpost/CreatePost";
import FetchUserPost from "./FetchUserPost";

import { Card, Spinner } from "react-bootstrap";
import clsx from "clsx";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { updateUser } from "../../components/redux/feature/user/userSlice";
import { useAuth } from "../../components/auth/UserContext";

import './profileStyle.css';

import { ReactSVG } from "react-svg";

import { storage } from "../../components/firebase/FirebaseConfig";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

import imageCompression from "browser-image-compression";
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ImageCropper from "../../components/ImageCropper";

import ViewCaddieAbout from "./profile/ViewCaddieAbout";

import FriendCount from "./module/FriendCount";

import ViewCaddieReqStatus from "./profile/ViewCaddieReqStatus";
import { ViewPlayerPerf, ViewCaddiePerf } from "./profile/ViewPerf";
import { imageConverter } from "../../components/imageConverter";

const defaultProfile = 'https://firebasestorage.googleapis.com/v0/b/doonine-e9d64.appspot.com/o/default_img.jpg?alt=media&token=6ac97b40-4386-4539-9c93-739c8baa3742';

function Userpage() {

    const maxLetter = 100;

    const userData = useSelector((state) => state.user.data);
    const dispatch = useDispatch();
    const { handleUpdateUserSendBird } = useAuth();

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const [description, setDescription] = useState('');
    const [editDescription, setEditDescription] = useState(false);
    const descriptionRef = useRef("");

    const [coverImage, setCoverImage] = useState(null);
    const [coverImageChanged, setCoverImageChanged] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [profileImageChanged, setProfileImageChanged] = useState(null);
    const [croppedModal, setCroppedModal] = useState(false);
    const [croppedProfileImage, setCroppedProfileImage] = useState(null);
    const [croppedCoverImage, setCroppedCoverImage] = useState(null);
    const isCoverRef = useRef(false);

    const [refreshKey, setRefreshKey] = useState(0);




    const handleRefresh = () => {

        setRefreshKey((prev) => prev + 1);
    }


    async function handleProfileImage(e, isCover) {
        const file = e.target.files[0];

        if (!file) return;

        let convertedUrl = null;

        if (isCover) {
            setCoverImageChanged(null);
        } else {
            setProfileImageChanged(null);
        }

        showCoppedModal();
        try {
            const convertedImage = await imageConverter(file);

            convertedUrl = URL.createObjectURL(convertedImage);
            // Update state based on whether it's a cover image or not
            if (convertedUrl) {
                if (isCover) {
                    setCoverImageChanged(convertedUrl);
                    isCoverRef.current = true;
                } else {
                    setProfileImageChanged(convertedUrl);
                    isCoverRef.current = false;
                }
            }
        } catch (error) {
            console.error("Error processing image:", error);
        }
    }


    const handleImageChange = (e, isCover) => {
        handleProfileImage(e, isCover)
        // Reset the input value to allow selecting the same file again
        e.target.value = null;
    };

    const showCoppedModal = () => setCroppedModal(true);
    const hideCroppedModal = () => setCroppedModal(false);

    const handleCroppedImage = (file, url) => {
        if (!isCoverRef.current) {
            setCroppedProfileImage(url);
            setProfileImage(file);
        } else {
            setCroppedCoverImage(url);
            setCoverImage(file);
        }
    }

    async function handleSaveImage() {
        if (userData) {

            const formData = {
                profileImage: userData.profileImage,
                coverImage: userData.coverImage || ""
            }

            setLoading(true);
            if (profileImageChanged) {
                const uploadedProfileImage = await uploadImage(profileImage, userData.uid, false);
                formData.profileImage = uploadedProfileImage || userData.profileImage;
                const updatedSendbirdImage = await handleUpdateUserSendBird(null, uploadedProfileImage, userData.dooNineId);
                if (!updatedSendbirdImage.success) {
                    console.error('Update send profile failed', updatedSendbirdImage.error);
                }
                setCroppedProfileImage(null);
            }

            if (coverImageChanged) {
                const uploadedCoverImage = await uploadImage(coverImage, userData.uid, true);
                formData.coverImage = uploadedCoverImage || userData.coverImage;
                setCroppedCoverImage(null);
            }
            if (profileImageChanged || coverImageChanged) {
                dispatch(updateUser(userData.uid, formData));
            }
            setEditMode(false);
            setLoading(false);
        }
    }

    async function uploadImage(file, name, isCover) {
        try {

            let convertedFile = await imageConverter(file)

            const compressedFile = await imageCompression(convertedFile, {
                maxSizeMB: 0.3,
                maxWidthOrHeight: 1920,
                useWebWorker: true
            });

            if (!isCover) {
                const storageRef = ref(storage, `profile_image/${name}.jpg`);
                await uploadBytes(storageRef, compressedFile);
                const downloadURL = await getDownloadURL(storageRef);
                // setProfileImageChanged(downloadURL);
                return downloadURL;
            } else {
                const storageRef = ref(storage, `profile_cover/${name}.jpg`);
                await uploadBytes(storageRef, compressedFile);
                const downloadURL = await getDownloadURL(storageRef);
                // setCoverImageChanged(downloadURL);
                return downloadURL;
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            return null;
        }
    }

    const handleDescriptionChange = (text) => {
        if (text.length <= maxLetter) {
            setDescription(text);
        }
    }

    async function handleSaveAbout(e) {
        e.preventDefault();
        descriptionRef.current = description;
        setEditDescription(false);
        dispatch(updateUser(userData.uid, {
            description: description
        }))
    }

    function handleEditAbout(e) {
        e.preventDefault();
        descriptionRef.current = userData?.description || "";
        setDescription(userData?.description || "");
        setEditDescription(true);
    }

    if (!userData) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="spinner-grow text-current" role="status"></div>
            </div>
        );
    }
    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />

            <div className="main-content right-chat-active" id="main-content-user-page">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left pe-0">
                        {userData ? <>
                            {userData?.role === 'player' ? (
                                /////////////////////// player ///////////////////////////
                                <div className="row">
                                    <div className="col-xl-12 mb-3 p-0 px-sm-3">
                                        <Card className="w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                            <Card.Body className="p-0 rounded-xxl overflow-hidden m-3 profile-cover-card" style={{ height: 250 }}>
                                                {croppedCoverImage || userData?.coverImage ? (
                                                    <PhotoProvider bannerVisible={false}>
                                                        <PhotoView src={croppedCoverImage || userData?.coverImage}>
                                                            <img src={croppedCoverImage || userData?.coverImage} alt="avater" style={{ objectFit: 'cover', width: '100%', height: '100%', cursor: 'pointer' }}
                                                                onError={(e) => {
                                                                    e.target.onerror = null;  // Prevents endless loop in case fallback image also fails
                                                                    e.target.src = defaultProfile;  // Replace with default image on error
                                                                }} />
                                                        </PhotoView>
                                                    </PhotoProvider>
                                                ) : (
                                                    <div className='w-100 h-100 bg-light'></div>
                                                )}
                                                {editMode && (
                                                    <>
                                                        <ReactSVG src="assets/images/dooNineIconV2/others/profile-edit-image.svg" className="profile-edit-image-icon" onClick={() => document.getElementById('profile-cover-input').click()} />
                                                        <input type="file" name="profile-cover-input" id="profile-cover-input" accept="image/*, .heic, .heif, image/heic, image/heif" className="d-none" onChange={(e) => { handleImageChange(e, true) }} />
                                                    </>
                                                )}
                                            </Card.Body>
                                            <Card.Body className="p-0 position-relative profile-image-card">
                                                <PhotoProvider bannerVisible={false}>
                                                    <figure className="avatar position-absolute w100 h100 z-index-1" style={{ top: '-40px', left: '30px' }}>
                                                        <PhotoView src={croppedProfileImage || userData?.profileImage || defaultProfile}>
                                                            <img src={croppedProfileImage || userData?.profileImage || defaultProfile} alt="avater"
                                                                className="float-right p-1 rounded-circle w-100 h-100 player-color" style={{ objectFit: 'cover', cursor: 'pointer' }}
                                                                onError={(e) => {
                                                                    e.target.onerror = null;  // Prevents endless loop in case fallback image also fails
                                                                    e.target.src = defaultProfile;  // Replace with default image on error
                                                                }}
                                                            />
                                                        </PhotoView>
                                                        {editMode && (
                                                            <>
                                                                <ReactSVG src="assets/images/dooNineIconV2/others/profile-edit-image.svg" className="profile-edit-image-icon" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    document.getElementById('profile-image-input').click();
                                                                }} />
                                                                <input type="file" name="profile-image-input" id="profile-image-input" accept="image/*, .heic, .heif, image/heic, image/heif" className="d-none" onChange={(e) => { handleImageChange(e, false) }} />
                                                            </>
                                                        )}
                                                    </figure>
                                                </PhotoProvider>

                                                <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{userData?.firstName || userData?.firstname} {userData?.lastName || userData?.lastname}
                                                    <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block text-truncate"><FriendCount user={userData} /></span>
                                                </h4>
                                                <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2 mb-3 mb-sm-0">
                                                    <Link to={'/my-perf-profile'}>
                                                        <button className="btn text-white p-2 px-3 player-color">My Performance</button>
                                                    </Link>
                                                    <button className={clsx("btn bg-light theme-dark-bg ms-2 p-2 px-3 d-block", { 'd-none': editMode })} onClick={() => setEditMode(true)}>
                                                        Edit
                                                    </button>
                                                    <button className={clsx("btn bg-success text-white ms-2 w70 p-2 px-3 d-block", { 'd-none': !editMode })} onClick={handleSaveImage}>
                                                        {!loading ? ('Save') : (
                                                            <Spinner variant="light" animation="border" role="status" size="sm">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        )}
                                                    </button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-xl-4 col-xxl-3 col-lg-4 pe-sm-0 px-sm-3 px-0 mb-2">
                                        <Card className="border border-0 shadow-xss rounded-xxl">
                                            {/* About */}
                                            <Card.Body>
                                                <h4 className="fw-600">About {editDescription && <span className="fs-6 text-black-50">{description.length}/{maxLetter}</span>}</h4>
                                                {editDescription ?
                                                    <textarea name="about-player"
                                                        rows={3}
                                                        value={description}
                                                        onChange={(e) => handleDescriptionChange(e.target.value)}
                                                        className="form-control right-scroll-bar"
                                                        style={{ minHeight: 70, maxHeight: 150, lineHeight: 1.2 }}
                                                    ></textarea>
                                                    :
                                                    (<>
                                                        {userData?.description && (userData?.description.length > 0 || descriptionRef.current.length > 0) ?
                                                            <div className="about-div bg-light theme-dark-bg right-scroll-bar">
                                                                <h6 className="m-0"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: (descriptionRef?.description || userData?.description)
                                                                            ?.replace(/\n/g, '<br/>')
                                                                            .replace(/ {2,}/g, (match) => '&nbsp;'.repeat(match.length)) || ''
                                                                    }}
                                                                ></h6>
                                                            </div>
                                                            :
                                                            <div className="about-div bg-light theme-dark-bg d-flex justify-content-center align-items-center"><h6 className="m-0">Say something to your friends</h6></div>
                                                        }
                                                    </>)
                                                }
                                                <div className="text-end mt-1">
                                                    {!editDescription ?
                                                        <button
                                                            className="btn btn-light theme-dark-bg border-0 p-2"
                                                            onClick={handleEditAbout}>
                                                            <h6 className="m-0 fw-600">Edit</h6>
                                                        </button>
                                                        :
                                                        <div className="d-flex gap-1 justify-content-end">
                                                            <button className="btn bg-current"><h6 className="m-0 fw-600 text-white" onClick={handleSaveAbout}>Save</h6></button>
                                                            <button className="btn bg-light theme-dark-bg" onClick={() => setEditDescription(false)}><h6 className="m-0 fw-600">Cancel</h6></button>
                                                        </div>
                                                    }
                                                </div>
                                            </Card.Body>
                                            {/* My peformance */}
                                            <Card.Body>
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="fw-600">My Performance</h4><Link to="/my-perf-profile">See all</Link>
                                                </div>
                                                <ViewPlayerPerf userId={userData.uid} />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-xl-8 col-xxl-9 col-lg-8 px-sm-3 px-0" >
                                        <CreatePost isRefresh={handleRefresh} />
                                        <FetchUserPost user={userData} key={refreshKey} handleEditedPost={handleRefresh} />
                                    </div>
                                </div>
                            ) : (
                                ///////////////////// caddie //////////////////////
                                <div className="row">
                                    <div className="col-xl-12 mb-3">
                                        <Card className="w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
                                            <Card.Body className="p-0 rounded-xxl overflow-hidden m-3 profile-cover-card" style={{ height: 250 }}>
                                                {userData?.coverImage ? (
                                                    <PhotoProvider bannerVisible={false}>
                                                        <PhotoView src={croppedCoverImage || userData?.coverImage}>
                                                            <img src={croppedCoverImage || userData?.coverImage} alt="avater" style={{ objectFit: 'cover', width: '100%', height: '100%', cursor: 'pointer' }} />
                                                        </PhotoView>
                                                    </PhotoProvider>
                                                ) : (
                                                    <div className='w-100 h-100 bg-light'></div>
                                                )}
                                                {editMode && (
                                                    <>
                                                        <ReactSVG src="assets/images/dooNineIconV2/others/profile-edit-image.svg" className="profile-edit-image-icon" onClick={() => document.getElementById('profile-cover-input').click()} />
                                                        <input type="file" name="profile-cover-input" id="profile-cover-input" accept="image/*, .heic, .heif, image/heic, image/heif" className="d-none" onChange={(e) => { handleImageChange(e, true) }} />
                                                    </>
                                                )}
                                            </Card.Body>
                                            <Card.Body className="p-0 position-relative profile-image-card">
                                                <PhotoProvider bannerVisible={false}>
                                                    <figure className="avatar position-absolute w100 h100 z-index-1" style={{ top: '-40px', left: '30px' }}>
                                                        <PhotoView src={croppedProfileImage || userData?.profileImage || defaultProfile}>
                                                            <img src={croppedProfileImage || userData?.profileImage || defaultProfile} alt="avater"
                                                                className="float-right p-1 rounded-circle w-100 h-100 caddie-color" style={{ objectFit: 'cover', cursor: 'pointer' }}
                                                                onError={(e) => {
                                                                    e.target.onerror = null;  // Prevents endless loop in case fallback image also fails
                                                                    e.target.src = defaultProfile;  // Replace with default image on error
                                                                }} />
                                                        </PhotoView>
                                                        {editMode && (
                                                            <>
                                                                <ReactSVG src="assets/images/dooNineIconV2/others/profile-edit-image.svg" className="profile-edit-image-icon" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    document.getElementById('profile-image-input').click();
                                                                }} />
                                                                <input type="file" name="profile-image-input" id="profile-image-input" accept="image/*, .heic, .heif, image/heic, image/heif" className="d-none" onChange={(e) => { handleImageChange(e, false) }} />
                                                            </>
                                                        )}
                                                    </figure>
                                                </PhotoProvider>

                                                <h4 className="fw-700 font-sm mt-2 mb-lg-5 mb-4 pl-15">{userData?.firstName || userData?.firstname} {userData?.lastName || userData?.lastname}
                                                    <span className="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block text-truncate"><FriendCount user={userData} /></span>
                                                </h4>
                                                <div className="d-flex align-items-center justify-content-center position-absolute-md right-15 top-0 me-2 mb-3 mb-sm-0">
                                                    <Link to={'/my-perf-profile'}>
                                                        <button className="btn text-white p-2 px-3 caddie-color">My Performance</button>
                                                    </Link>
                                                    <button className={clsx("btn bg-light theme-dark-bg ms-2 p-2 px-3 d-block", { 'd-none': editMode })} onClick={() => setEditMode(true)}>
                                                        Edit
                                                    </button>
                                                    <button className={clsx("btn bg-success text-white ms-2 p-2 px-3 w70 d-block", { 'd-none': !editMode })} onClick={handleSaveImage}>
                                                        {!loading ? ('Save') : (
                                                            <Spinner variant="light" animation="border" role="status" size="sm">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </Spinner>
                                                        )}
                                                    </button>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-xl-4 col-xxl-3 col-lg-4 pe-sm-0 px-sm-3 px-0 mb-2">
                                        <Card className="border border-0 shadow-xss rounded-xxl">
                                            {/* About */}
                                            <Card.Body>
                                                <ViewCaddieAbout data={userData} />
                                            </Card.Body>
                                            <Card.Body>
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="fw-600">My Course</h4><Link to="/caddie-req">Edit</Link>
                                                </div>
                                                <ViewCaddieReqStatus userData={userData} />
                                            </Card.Body>
                                            {/* My peformance */}
                                            <Card.Body>
                                                <div className="d-flex justify-content-between">
                                                    <h4 className="fw-600">My Performance</h4><Link to="/my-perf-profile">See all</Link>
                                                </div>
                                                <ViewCaddiePerf userId={userData.uid} />
                                            </Card.Body>
                                        </Card>
                                    </div>
                                    <div className="col-xl-8 col-xxl-9 col-lg-8 px-sm-3 px-0" >
                                        <CreatePost isRefresh={handleRefresh} />
                                        <FetchUserPost user={userData} key={refreshKey} handleEditedPost={handleRefresh} />
                                    </div>
                                </div>
                            )}
                        </>
                            :
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '80vh' }}>
                                <div className="spinner-grow text-current" role="status"></div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />

            <ImageCropper
                show={croppedModal}
                hide={hideCroppedModal}
                profileImage={isCoverRef.current ? coverImageChanged : profileImageChanged}
                croppedImage={handleCroppedImage}
                isCover={isCoverRef.current}
            />

        </>
    );
}

export default Userpage;