import React, { useState, useEffect, useRef, useMemo } from 'react'
import { firestore } from '../../../../components/firebase/FirebaseConfig';
import { collection, query, where, getDocs, or, and } from 'firebase/firestore';
import Load from '../../../../components/Load';
import { ReactSVG } from 'react-svg';
import { useSelector } from 'react-redux';
import { ListGroup, Modal } from 'react-bootstrap';
import { useWizard } from '../wizard/wizardContext';

const CaddieModal = ({ isOpen, handleClose, selectedCaddie, inServiceCaddie }) => {

    const userData = useSelector((state) => state.user.data);
    const { stepData } = useWizard();
    const data1 = stepData.stepOne;
    // const data2 = stepData.stepTwo;

    const [loadingUser, setLoadingUser] = useState(true);
    const [caddieDataList, setCaddieDataList] = useState([]);
    const caddieIdRef = useRef([]);
    const favCaddieRef = useRef([]);
    const friendCaddieRef = useRef([]);
    const caddieDataRef = useRef([]);

    // const [searchInput, setSearchInput] = useState("");

    // console.log(data1);

    useEffect(() => {
        const getCaddieData = async (reqList) => {
            if (!reqList.length) return;

            const caddieList = reqList
                .map((data) => data.caddieId)
                .filter((id) => id !== userData.uid);

            caddieIdRef.current = caddieList;
            const caddieDataListArray = []
            try {
                const userCollection = collection(firestore, 'users');
                const q = query(userCollection, where('uid', 'in', caddieList));
                const userSnapshot = await getDocs(q);
                if (!userSnapshot.empty) {
                    userSnapshot.forEach(doc => {
                        caddieDataListArray.push(doc.data())
                    });
                    caddieDataRef.current = caddieDataListArray
                    await getYourFav(caddieList);
                    await getYourFriend(caddieList);
                    sortCaddie()
                }
            } catch (error) {
                console.error('Fail to get caddie data', error);
            }
        }

        const getCaddieInCourse = async (courseId) => {
            try {

                const reqList = [];

                const caddieReqCollection = collection(firestore, 'caddieRequests');
                const q = query(caddieReqCollection,
                    where('courseId', '==', courseId),
                    where('status', '==', 'approved'),
                );
                const reqSnapshot = await getDocs(q);

                if (!reqSnapshot.empty) {
                    reqSnapshot.forEach(doc => {
                        reqList.push(doc.data())
                    });
                    await getCaddieData(reqList);
                }
                setLoadingUser(false);
            } catch (error) {
                setLoadingUser(false);
                console.error('Fail to get caddie req course', error);
            }
        }
        const getYourFav = async (caddieList) => {
            if (!caddieList.length) return;

            favCaddieRef.current = []
            try {
                const favCollection = collection(firestore, 'favoriteFriends');
                const q = query(favCollection,
                    where('caddieId', 'in', caddieList),
                    where('userId', '==', userData.uid),
                    where('status', '==', 'active'));
                const favSnapshot = await getDocs(q);
                if (!favSnapshot.empty) {
                    favSnapshot.forEach(doc => {
                        favCaddieRef.current.push(doc.data())
                    });
                }
                // console.log('fav ', favCaddieRef.current);

            } catch (error) {
                console.error('Fail to get fav caddie', error);
            }
        }
        const getYourFriend = async (caddieList) => {
            if (!caddieList.length) return;

            friendCaddieRef.current = []
            try {
                const friendCollection = collection(firestore, 'friendRequests');
                const q = query(friendCollection,
                    or(
                        and(
                            where('fromUserId', '==', userData.uid),
                            where('toUserId', 'in', caddieList),
                            where('status', '==', 'accept')
                        ),
                        and(
                            where('toUserId', '==', userData.uid),
                            where('fromUserId', 'in', caddieList),
                            where('status', '==', 'accept')
                        )
                    )
                )
                const friendSnapshot = await getDocs(q);
                if (!friendSnapshot.empty) {
                    friendSnapshot.forEach(doc => {
                        friendCaddieRef.current.push(doc.data())
                    });
                }
                // console.log('friend ', friendCaddieRef.current);

            } catch (error) {
                console.error('Fail to get friend caddie', error);
            }
        }

        const sortCaddie = () => {
            const favCaddies = favCaddieRef.current.map(fav => fav.caddieId);
            const friendCaddies = friendCaddieRef.current.map(friend => friend.fromUserId === userData.uid ? friend.toUserId : friend.fromUserId);
            // console.log(favCaddies);
            // console.log(friendCaddies);
            // console.log(caddieDataRef.current);


            const updatedCaddieDataList = caddieDataRef.current.map(caddie => {
                return {
                    ...caddie,
                    isFav: favCaddies.includes(caddie.uid),
                    isFriend: friendCaddies.includes(caddie.uid)
                };
            });

            setCaddieDataList(() => {
                return updatedCaddieDataList.sort((a, b) => {

                    if (a.isFav !== b.isFav) {
                        return b.isFav - a.isFav;
                    }
                    if (a.isStar !== b.isStar) {
                        return b.isStar - a.isStar;
                    }
                    if (a.isFriend !== b.isFriend) {
                        return b.isFriend - a.isFriend;
                    }
                    return 0;
                });
            });
        };
        getCaddieInCourse(data1.course.id)

    }, [data1.course.id, userData])


    const renderCaddieList = useMemo(() => {
        const handleCaddieClick = (caddie) => {
            if (!inServiceCaddie.includes(caddie.uid)) {
                selectedCaddie(caddie);
                handleClose();
            }
        }

        return (
            <>
                <style>
                    {`.sel-caddie-item:hover{
background-color: rgba(155, 155, 155, 0.350)
}`}
                </style>
                <ListGroup>
                    {caddieDataList.map((caddie, index) =>
                        <ListGroup.Item key={index} className='sel-caddie-item pointer' onClick={() => handleCaddieClick(caddie)}
                            style={{ backgroundColor: inServiceCaddie.includes(caddie.uid) ? 'rgba(155, 155, 155, 0.350)' : '' }}
                        >
                            <div className='d-flex align-items-center'>
                                <div className='col-3'>
                                    <img src={caddie.profileImage} alt={`user${index}`} width={50} height={50} className='rounded-circle caddie-color-outline' style={{ objectFit: 'cover' }} />
                                </div>
                                <div className='col-6'>
                                    <h4 className='fw-600'>{`${caddie.firstName} ${caddie.lastName}`} <br />
                                        {caddie.isStar ?
                                            <ReactSVG src='assets/images/dooNineIconV3/caddieFriendFav/caddieFriendFav-caddieStar.svg' wrapper='svg' width={25} height={25} />
                                            : null
                                        }
                                    </h4>
                                </div>
                                <div className='col-3 text-end'>
                                    {caddie.isFav ?
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-favCaddie.svg' wrapper='svg' width={30} height={30} />
                                        : null
                                    }
                                    {caddie.isFriend ?
                                        <ReactSVG src='assets/images/dooNineIconV3/create-round/createRound-friendCaddie.svg' wrapper='svg' width={30} height={30} />
                                        : null
                                    }
                                </div>
                            </div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </>
        )
    }, [caddieDataList, handleClose, inServiceCaddie, selectedCaddie])

    return (
        <>
            <style>
                {`.sel-caddie-card-list:hover {
  background-color: #f5f5f5a1;
}
`}
            </style>
            <Modal show={isOpen} onHide={handleClose} centered animation={false}>
                <Modal.Header>
                    <Modal.Title className='d-flex justify-content-between align-items-center gap-4 w-100'>
                        <h2 className='fw-600 text-nowrap'>Select Caddie</h2>
                        <div className="search-form-2 flex-grow-1">
                            <i className="ti-search font-xss"></i>
                            <input
                                type="text"
                                className="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0"
                                placeholder="Search here."
                            />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='right-scroll-bar' style={{ maxHeight: '60vh' }}>
                        {!loadingUser ?
                            renderCaddieList
                            :
                            <Load />
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CaddieModal