import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/Header";
import Leftnav from "../../components/Leftnav";
import Rightchat from "../../components/Rightchat";
import Appfooter from "../../components/Appfooter";
import Popupchat from "../../components/Popupchat";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { collection, doc, getDoc, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "../../components/firebase/FirebaseConfig";
import LoadCircle from "../../components/LoadCircle";
import Table from "./component/Table";
import Swal from "sweetalert2";
import CreateLink from "./component/CreateLink";

function LeaderboardIndex() {
    const params = new URLSearchParams(window.location.search);
    const roundId = params.get("round");
    const [roundData, setRoundData] = useState({});
    const [loading, setLoading] = useState(true);
    const userData = useSelector((state) => state.user.data);
    const navigate = useNavigate()

    useEffect(() => {
        let unsubscribeRound = null;

        async function getRoundData(roundId) {
            let courseHoleData = {}, roundData = {};

            try {
                // Listen for changes to round data
                const roundDoc = doc(firestore, 'round', roundId);
                unsubscribeRound = onSnapshot(roundDoc, async (roundSnapshot) => {
                    if (roundSnapshot.exists()) {
                        roundData = roundSnapshot.data();

                        if (!roundData.isLeaderBoardPublic) {
                            const userList = roundData.userList;
                            const foundUser = userList.find(item =>
                                (item.player && item.player.uid === userData.uid) ||
                                (item.caddie && item.caddie.uid === userData.uid)
                            );
                            if (!foundUser) {
                                navigate("/round-activity");

                                Swal.fire({
                                    title: "Round is Private",
                                    html: `
                                    <h5 class="fw-600">The round you selected is private.</h5>
                                    <h5 class="fw-600">Please check and try again.</h5>
                                    `,
                                    icon: "error",
                                    confirmButtonColor: "#ee3d7f",
                                    confirmButtonText: "OK",
                                })
                                return 
                            }
                        }

                        // Fetch course data if applicable
                        if (roundData.holesCourse) {
                            const courseHoleDoc = doc(firestore, 'courseHoles', roundData.holesCourse);
                            const courseHoleSnapshot = await getDoc(courseHoleDoc);
                            if (courseHoleSnapshot.exists()) {
                                courseHoleData = courseHoleSnapshot.data();
                            } else {
                                console.error('Not found hole course:', roundData.holesCourse);
                            }
                        } else {
                            console.warn('Not select course hole yet');
                        }

                        // Update state with both round and course data
                        setRoundData({
                            ...roundData,
                            courseHoleData: courseHoleData
                        });
                    } else {
                        console.error('Not found round:', roundId);
                    }
                });
            } catch (error) {
                setRoundData({
                    ...{},
                    courseHoleData: {}
                });
            } finally {
                setLoading(false);
            }
        }

        if (roundId && userData) {
            getRoundData(roundId);
        }

        return () => {
            // Cleanup listener
            if (unsubscribeRound) unsubscribeRound();
        };
    }, [roundId, userData]);



    // console.log(roundData);

    return (
        <>
            <Header />
            <Leftnav />
            <Rightchat />

            <div className="main-content right-chat-active">
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                {!loading ?
                                    <>
                                        {/* nav */}
                                        <div className="card-body p-4 w-100 bg-current border-0 d-flex justify-content-between align-items-center rounded-3">
                                            <div className="d-flex align-items-center">
                                                <Link to={-1} className="d-inline-block mt-2">
                                                    <i className="ti-arrow-left font-sm text-white"></i>
                                                </Link>
                                                <div>
                                                    <div className='mx-2'>
                                                        <h3 className='fw-700 text-white'>{roundData.roundName}</h3>
                                                        <h6 className='fw-600 text-white text-capitalize'>{`${roundData.holes}(${roundData.courseHoleData?.courseHoleName || ""}), ${roundData.gameMode}(${roundData.scoring})`}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* body */}
                                        <div className="card-body p-lg-3 p-4 w-100 border-0">
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <h2 className="fw-600 mb-3">Leaderboard</h2>
                                                </div>
                                                <Table roundData={roundData} />
                                                {roundData.creator === userData.uid ?
                                                    <CreateLink roundData={roundData} />
                                                    :
                                                    null
                                                }
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="p-4 text-center">
                                        <LoadCircle size="lg" variant="dark" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Popupchat />
            <Appfooter />

        </>
    )
}

export default LeaderboardIndex