import React, { useMemo } from 'react'
import Chart from "react-apexcharts";

export const ScoreByParChart = ({ userId = null, completedRound, courseHoleList }) => {

    const renderChart = useMemo(() => {
        if (!userId || !courseHoleList) return

        const options = {
            chart: {
                id: 'responsive-bar',
            },
            xaxis: {
                categories: ['Par 3', 'Par 4', 'Par 5'],
            },
            yaxis: {
                // max: 6,
            },
            title: {
                text: 'Score By Par',
                align: 'center',
                style: {
                    fontSize: '16px',
                    color: '#333'
                }
            },
            colors: ['#008FFB80'],
            plotOptions: {
                bar: {
                    borderRadius: 5,
                    horizontal: false,
                    distributed: false,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
        }


        let series = [{
            name: '',
            data: []
        }];

        let totalPar2Score = 0;
        let totalPar2Count = 0;
        let totalPar3Score = 0;
        let totalPar3Count = 0;
        let totalPar4Score = 0;
        let totalPar4Count = 0;
        let totalPar5Score = 0;
        let totalPar5Count = 0;
        let totalStroke = 0;
        let totalSkipping = 0;

        completedRound.forEach(round => {
            courseHoleList.forEach(course => {
                if (course.roundIds.includes(round.roundId)) {
                    // Found round id in that course
                    course.holeDetails.forEach(hole => {
                        const holeData = hole;
                        const scoreData = round[`hole${hole.holeNumber}`];

                        // Convert hole par and score to numbers
                        const holePar = Number(holeData.par);
                        const score = Number(scoreData.score);

                        // Check if score and hole par are valid numbers
                        if (!isNaN(score) && !isNaN(holePar)) {
                            // Update total strokes count
                            totalStroke += 1;

                            // Calculate total score and count based on hole par
                            switch (holePar) {
                                case 2:
                                    totalPar2Score += score;
                                    totalPar2Count += 1;
                                    break;
                                case 3:
                                    totalPar3Score += score;
                                    totalPar3Count += 1;
                                    break;
                                case 4:
                                    totalPar4Score += score;
                                    totalPar4Count += 1;
                                    break;
                                case 5:
                                    totalPar5Score += score;
                                    totalPar5Count += 1;
                                    break;

                                default:
                                    console.warn('Not count for par :', holePar);
                                    break;
                            }

                        } else {
                            totalSkipping += 1;
                        }
                    });
                }
            });
        });

        // Calculate averages for each par
        const averagePar2 = totalPar2Count > 0 ? totalPar2Score / totalPar2Count : 0;
        const averagePar3 = totalPar3Count > 0 ? totalPar3Score / totalPar3Count : 0;
        const averagePar4 = totalPar4Count > 0 ? totalPar4Score / totalPar4Count : 0;
        const averagePar5 = totalPar5Count > 0 ? totalPar5Score / totalPar5Count : 0;

        // Output the averages
        // console.log("Average score for Par 2:", averagePar2);
        // console.log("Average score for Par 3:", averagePar3);
        // console.log("Average score for Par 4:", averagePar4);
        // console.log("Average score for Par 5:", averagePar5);
        // console.log("Total Skipped:", totalSkipping);
        // console.log("Total Strokes Counted:", totalStroke);

        series = [{
            name: '',
            data: [averagePar3.toFixed(2), averagePar4.toFixed(2), averagePar5.toFixed(2)]
        }]

        return (
            <div className="chart-container" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
                <Chart
                    options={options}
                    series={series}
                    type="bar"
                    width="100%"
                    height="300"
                />
            </div>
        )
    }, [userId, courseHoleList, completedRound])

    return (
        <>
            {renderChart}
        </>
    );
}