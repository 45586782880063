import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { firestore } from '../../../components/firebase/FirebaseConfig';
import { gameModeSelection } from '../module/scoreCalculation';

function Table({ roundData }) {
    const [scoreList, setScoreList] = useState([]);
    const [userLoaded, setUserLoaded] = useState(false);
    const [scoreLoaded, setScoreLoaded] = useState(false);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        async function getUserdata(roundData) {
            try {
                const userList = roundData.userList;
                const uids = [];

                // Collect all unique UIDs from userList
                userList.forEach(user => {
                    if (user.player) {
                        uids.push(user.player.uid);
                    }
                    if (user.caddie) {
                        uids.push(user.caddie.uid);
                    }
                });

                // Query Firestore for user data
                const userCollection = collection(firestore, 'users');
                const q = query(userCollection, where('uid', 'in', uids));
                const userSnapshot = await getDocs(q);

                if (!userSnapshot.empty) {
                    // Create a mapping of UID to user data
                    const userDataMap = {};
                    userSnapshot.forEach(userDoc => {
                        userDataMap[userDoc.data().uid] = userDoc.data();
                    });

                    // Update the userList with fetched user data
                    const updatedUserList = userList.map(user => ({
                        ...user,
                        player: user.player
                            ? { ...user.player, ...userDataMap[user.player.uid] }
                            : null,
                        caddie: user.caddie
                            ? { ...user.caddie, ...userDataMap[user.caddie.uid] }
                            : null,
                    }));
                    const deepCopiedUserList = JSON.parse(JSON.stringify(updatedUserList));
                    setUserList(deepCopiedUserList)
                    roundData.userList = updatedUserList
                }
                setUserLoaded(true);
            } catch (error) {
                setUserLoaded(false);
                console.error('Error fetching user data:', error);
            }
        }

        if (Object.keys(roundData).length) {
            getUserdata(roundData);

        }
    }, [roundData]);

    useEffect(() => {
        // Ensure roundData and roundData.roundId are available
        if (!roundData || !roundData.roundId || !userLoaded) {
            return;
        }

        // Reference to the 'scoreCards' collection
        const scoreCollection = collection(firestore, 'scoreCards');

        // Create a query against the collection where 'roundId' matches
        const q = query(scoreCollection, where('roundId', '==', roundData.roundId));

        // Set up the onSnapshot listener
        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (!snapshot.empty) {
                const scoreArr = [];
                snapshot.forEach((doc) => {
                    scoreArr.push({ id: doc.id, ...doc.data() });
                });

                const sortedScore = gameModeSelection(scoreArr, roundData);
                const userIdOrderMap = new Map(
                    sortedScore.map((item, index) => [item.userId, index])
                );

                // Create a new sorted array based on the order of userId in sortedScore.
                const sortedUserList = [...userList].sort((a, b) => {
                    const indexA = a.player ? userIdOrderMap.get(a.player.uid) : Infinity; // If player is null, push to end
                    const indexB = b.player ? userIdOrderMap.get(b.player.uid) : Infinity; // If player is null, push to end
                    return (indexA ?? Infinity) - (indexB ?? Infinity); // Use Infinity if userId not found
                });

                setUserList(sortedUserList);
                setScoreList(sortedScore);
            } else {
                console.warn('No scores found for this round');

                // Map all users with empty scores if the snapshot is empty
                const emptyScoreList = userList.map(user => ({
                    id: null,
                    userId: user.player ? user.player.uid : null,
                    caddieId: user.caddie ? user.caddie.uid : null,
                    score: null, // Placeholder for empty score
                }));

                setScoreList(emptyScoreList);
            }
            setScoreLoaded(true)
        });

        // Cleanup function to unsubscribe from the listener when the component unmounts or roundId changes
        return () => {
            unsubscribe();
        };
    }, [roundData, userLoaded]);

    // console.log(roundData);
    // console.log(scoreList);
    // console.log(userList);



    return (
        <>
            <div className='p-2'>
                {scoreLoaded && !scoreList.length &&
                    <div className='text-center'><h4 className='fw-600 text-muted'>Not found score in this round</h4></div>
                }
                {userList.map((user, idx) =>
                    <div className='d-flex mb-2 border rounded-3 py-1' key={idx}>
                        {/* number */}
                        <div className='col-2 p-2'>
                            <div className='d-flex justify-content-start w-100 h-100'>
                                <h1 className='fw-600 text-current m-1'>{idx + 1}</h1>
                            </div>
                        </div>
                        {/* user */}

                        <div className='col-5 p-2'>
                            <div className='d-flex justify-content-center'>
                                {user.player && user?.player?.isJoin ?
                                    <img src={user?.player?.profileImage} alt="player" width={50} height={50} style={{ zIndex: 1 }} className='rounded-circle player-color-outline' />
                                    :
                                    <>
                                        {user.player && !user?.player?.isJoin ?
                                            <img src={user?.player?.profileImage} alt="player" width={50} height={50} className='rounded-circle player-color-outline' style={{ opacity: 0.3 }} />
                                            :
                                            null
                                        }
                                    </>
                                }
                                {user.caddie && user?.caddie?.isJoin ?
                                    <img src={user?.caddie?.profileImage} alt="caddie" width={50} height={50} className='rounded-circle caddie-color-outline' />
                                    :
                                    <>
                                        {user.caddie && !user?.caddie?.isJoin ?
                                            <img src={user?.caddie?.profileImage} alt="caddie" width={50} height={50} className='rounded-circle caddie-color-outline' style={{ opacity: 0.3 }} />
                                            :
                                            null
                                        }
                                    </>
                                }
                                {!user.player && !user.caddie ?
                                    <div className='d-flex align-items-center justify-content-center w-100' style={{ height: 50, }}>
                                        <h4 className='fw-600'>No player</h4>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>

                        {/* score */}
                        <div className='col-5 p-2'>
                            <div className='h-100 d-flex flex-column justify-content-center align-items-center'>
                                {(() => {
                                    const foundScore = scoreList.find(item => item.userId === user?.player?.uid);
                                    return foundScore ? (
                                        <>
                                            <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                                <h5 className='fw-600 text-danger'>{typeof foundScore.sumPenal === 'number' ? foundScore.sumPenal : "-"}</h5>
                                                <h1 className="fw-700 mx-2">
                                                    {
                                                        typeof foundScore.sumScore === 'number'
                                                            ? roundData.scoring === 'To Par'
                                                                ? foundScore.sumScore > 0
                                                                    ? `+${foundScore.sumScore}` // Add a '+' for positive scores
                                                                    : foundScore.sumScore       // Display the score as-is for non-positive numbers
                                                                : foundScore.sumScore          // Fallback if roundData.scoring is not 'To Par'
                                                            : "-"                       // Display a dash if sumScore is not a number
                                                    }
                                                </h1>
                                                <h5 className='fw-600'>{typeof foundScore.sumPutt === 'number' ? foundScore.sumPutt : "-"}</h5>
                                            </div>
                                            {foundScore.status === 'playing' &&
                                                <h5 className='fw-600 text-capitalize'>{foundScore.status || ""}</h5>
                                            }
                                            {foundScore.status === 'finished' &&
                                                <h5 className='fw-600 text-capitalize text-success'>{foundScore.status || ""}</h5>
                                            }
                                            {foundScore.status === 'unfinished' &&
                                                <h5 className='fw-600 text-capitalize text-warning'>{foundScore.status || ""}</h5>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
                                                <h5 className='fw-600 text-danger'>-</h5>
                                                <h1 className="fw-700 mx-2">-</h1>
                                                <h5 className='fw-600'>-</h5>
                                            </div>
                                        </>
                                    );
                                })()}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default Table