import { firestore } from "../../../../../components/firebase/FirebaseConfig";
import { where, query, getDocs, updateDoc, collection, serverTimestamp, doc, getDoc, addDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import { updateScoreCard } from "./updateScoreCard";
import axios from "axios";
import { joinRoundNotification } from "../../../../../components/notification/sentNotification";
const sendBird_api_token = process.env.REACT_APP_SENDBIRD_API_TOKEN;

export const confirmJoin = async (roundData, confirmCaddie, userData, yourCaddie, setLoading, navigate, userList) => {
    // console.log(userList);
    // console.log(copiedRounData);
    // console.log(roundData);

    try {
        setLoading(true);

        const foundPlayer = roundData.userList.find(item => item.player && item.player.uid === userData.uid);
        if (foundPlayer) {
            // invite player
            if (foundPlayer.caddie) {
                // already have caddie
                if (yourCaddie && yourCaddie.uid === foundPlayer.caddie.uid) {
                    // same caddie
                    foundPlayer.caddie = {
                        ...foundPlayer.caddie,
                        playerConfirm: confirmCaddie
                    }
                } else if (yourCaddie && yourCaddie.uid !== foundPlayer.caddie.uid) {
                    // diff caddie
                    foundPlayer.caddie = {
                        playerConfirm: confirmCaddie,
                        isCreator: false,
                        isJoin: false,
                        uid: yourCaddie.uid
                    }
                } else {
                    // not select or no caddie
                    foundPlayer.caddie = null
                }
            } else {
                // not have caddie
                if (yourCaddie) {
                    // select caddie
                    foundPlayer.caddie = {
                        playerConfirm: confirmCaddie,
                        isCreator: false,
                        isJoin: false,
                        uid: yourCaddie.uid
                    }
                } else {
                    // not select
                    foundPlayer.caddie = null
                }
            }
            foundPlayer.player.isJoin = true
        } else {
            // no invite player
            // console.log(userList);
            const foundNewPlayer = userList.find(item => item.player && item.player.uid === userData.uid);

            if (foundNewPlayer) {
                // found new player (you)
                const foundNewPlayerRow = roundData.userList.find(item => item.row === foundNewPlayer.row);
                if (foundNewPlayerRow) {
                    // found your selected row
                    if (yourCaddie) {
                        // found your caddie
                        foundNewPlayerRow.player = {
                            isCreator: false,
                            isJoin: true,
                            uid: foundNewPlayer.player.uid
                        }

                        foundNewPlayerRow.caddie = {
                            isCreator: yourCaddie.isCreator || false,
                            isJoin: yourCaddie.isJoin || false,
                            uid: yourCaddie.uid,
                            playerConfirm: confirmCaddie || false
                        }

                    } else {
                        // not found your caddie
                        foundNewPlayerRow.player = {
                            isCreator: false,
                            isJoin: true,
                            uid: foundNewPlayer.player.uid
                        }

                        foundNewPlayerRow.caddie = null
                    }
                }
            } else {
                // not found new player (you)
                setLoading(false);
                return;
            }

        }

        // console.log(roundData);

        const docRef = doc(firestore, 'round', roundData.roundId);
        const roundSnapshot = await getDoc(docRef);

        const foundRoundDataUser = roundData.userList.find(
            item => item.player && item.player.uid === userData.uid
        );

        if (roundSnapshot.exists()) {
            // Find the user in the provided roundData.userList


            // Retrieve the original userList from Firestore
            const originalUserList = roundSnapshot.data()?.userList || [];

            // Update the userList based on whether we found the user in roundData
            const updatedUserList = originalUserList.map(user => {
                if (user.player && user.player.uid === userData.uid) {
                    // If found in roundData, use that; otherwise, use the original data
                    return foundRoundDataUser ? { ...user, ...foundRoundDataUser } : user;
                }
                return user;
            });

            // Write the updated list back to Firestore
            await updateDoc(docRef, {
                userList: updatedUserList,
                lastUpdate: serverTimestamp()
            });

        } else {
            console.error(`Round document not found: ${roundData.roundId}`);
        }

        await updateScoreCard(roundData.userList, roundData.roundId);

        const userUids = roundData.userList.reduce((acc, item) => {
            if (item.player && item.player.uid) {
                acc.push(item.player.uid);
            }
            if (item.caddie && item.caddie.uid) {
                acc.push(item.caddie.uid);
            }
            return acc;
        }, []);

        const roundActivityCollection = collection(firestore, 'roundActivity');
        const q = query(roundActivityCollection, where('roundId', '==', roundData.roundId));
        const roundActivitySnapshot = await getDocs(q);
        if (!roundActivitySnapshot.empty) {
            const activityRef = roundActivitySnapshot.docs[0].ref;
            updateDoc(activityRef, {
                userList: userUids,
                lastUpdate: serverTimestamp()
            })
        }

        if (roundData.groupChat === 'create' && roundData.channelUrl) {
            console.log(userData.dooNineId);
            console.log(roundData.channelUrl);

            try {
                const response = await axios.put(
                    `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/group_channels/${roundData.channelUrl}/accept`,
                    { user_id: userData.dooNineId },
                    {
                        headers: {
                            "Content-Type": "application/json; charset=utf8",
                            "Api-Token": sendBird_api_token,
                        },
                    }
                );
                console.log("Joined successfully:", response.data);
                // const channelInfo = await axios.get(
                //     `https://api-B72515CE-E0DA-417F-AF02-4BF3D07B3D50.sendbird.com/v3/users/${userData.dooNineId}/channel_invitation_preference`,
                //     {
                //         headers: { "Api-Token": sendBird_api_token }
                //     }
                // );
                // console.log("Channel Info:", channelInfo.data);

            } catch (error) {
                if (error.response) {
                    console.error('Failed to join group chat:', error.response.data);
                } else {
                    console.error('Error:', error.message);
                }
            }
        }

        // send notification

        try {
            if (foundRoundDataUser) {
                if (foundRoundDataUser?.caddie?.uid && foundRoundDataUser?.player?.uid) {
                    const noticollection = collection(firestore, 'notifications');
                    const q = query(noticollection,
                        where('roundId', '==', roundData.roundId),
                        where('fromUserId', '==', foundRoundDataUser.player.uid),
                        where('toUserId', '==', foundRoundDataUser.caddie.uid),
                        where('isRead', '==', false)
                    )
                    const notiSnapshot = await getDocs(q);
                    if (notiSnapshot.empty) {
                        if (foundRoundDataUser.caddie.playerConfirm && !foundRoundDataUser.caddie.isJoin) {
                            await joinRoundNotification(foundRoundDataUser.player.uid, [foundRoundDataUser.caddie.uid], roundData.roundName, roundData.roundId)
                        }
                    }
                }
            }
        } catch (error) {
            console.error('fail to send notification to caddie', foundRoundDataUser.caddie.uid);
        }

        setLoading(false);

        Swal.fire({
            title: 'Joined',
            icon: 'success',
            confirmButtonColor: '#ee3c7f'
        }).then(() => {
            navigate('/round-activity')
        })

    } catch (error) {
        setLoading(false);
        console.error('Fail to join', error);
    }
}